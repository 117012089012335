// material-ui
import { Box, FormControl, InputAdornment, OutlinedInput } from "@mui/material";

// assets
import { SearchNormal1 } from "iconsax-react";

// ==============================|| HEADER CONTENT - SEARCH ||============================== //

const Search = () => (
  <Box
    sx={{ width: { xl: "38%", xxl: "50%", xxxl: "54%" }, ml: { xs: 0, md: 2 } }}
  >
    <FormControl sx={{ width: { xs: "71%", md: 224 } }}>
      {/* <OutlinedInput
        id="header-search"
        startAdornment={
          <InputAdornment position="start" sx={{ mr: -0.5 }}>
            <SearchNormal1 size={16} />
          </InputAdornment>
        }
        aria-describedby="header-search-text"
        inputProps={{
          'aria-label': 'weight'
        }}
        placeholder="Ctrl + K"
        sx={{ '& .MuiOutlinedInput-input': { p: 1.5 } }}
      /> */}
    </FormControl>
  </Box>
);

export default Search;
