import widget from "./widget";
import history from "./history";
import couponKey from "./coupon_key";
import support from "./support";
import getLinksGroup from "./links_group";

const Menuitems = (breadcrumbs) => {
  const items = [
    widget,
    getLinksGroup({
      spaceDetailsTitle: breadcrumbs?.spaceDetailsName,
      bulkLinkDetailsTitle: breadcrumbs?.bulkLinkDetailsName,
    }),
    history,
    couponKey,
    support(breadcrumbs?.planDetails),
  ];

  return { items };
};

export default Menuitems;
