import React, { useEffect, useState } from "react";
import Confetti from "react-confetti";

const ConfettiBlast = ({ calls }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (calls) {
      setIsVisible(true);
      setTimeout(() => {
        setIsVisible(false);
      }, 3200);
    }
  }, [calls]);

  return (
    <div style={{ zIndex: 999999 }}>
      <Confetti
        style={{ zIndex: 999999999 }}
        numberOfPieces={isVisible ? 420 : 0}
        height={window.height}
      />
    </div>
  );
};

export default ConfettiBlast;
