import React from "react";

const BookmarkBTN_icon = () => {
  return (
    <>
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M15.4219 1.83398H6.58521C4.63271 1.83398 3.04688 3.42898 3.04688 5.37232V18.2882C3.04688 19.9382 4.22938 20.6348 5.67771 19.8373L10.151 17.3532C10.6277 17.0873 11.3977 17.0873 11.8652 17.3532L16.3385 19.8373C17.7869 20.644 18.9694 19.9473 18.9694 18.2882V5.37232C18.9602 3.42898 17.3744 1.83398 15.4219 1.83398Z"
          fill="#5B6B79"
        />
        <path
          d="M11.0017 9.42277C10.0667 9.42277 9.13165 9.25777 8.24248 8.93694C7.88498 8.8086 7.70165 8.41444 7.82998 8.05694C7.96748 7.69944 8.36165 7.5161 8.71915 7.64444C10.195 8.1761 11.8175 8.1761 13.2933 7.64444C13.6508 7.5161 14.045 7.69944 14.1733 8.05694C14.3017 8.41444 14.1183 8.8086 13.7608 8.93694C12.8717 9.25777 11.9367 9.42277 11.0017 9.42277Z"
          fill="#5B6B79"
        />
      </svg>
    </>
  );
};

export default BookmarkBTN_icon;
