import { Box, Button, Typography } from "@mui/material";
import React from "react";
import Logo from "../../Component/logo";
import DomainSuccessImage from "../../assets/images/Domain/domain-success.png";
import { useTranslation } from "react-i18next";

const DomainSuccess = () => {
  const { t } = useTranslation();
  const handleOnClick = () => {
    window.location.href = "/dashboard";
  };

  return (
    <>
      <Box
        sx={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            width: { sm: "500px", xsm: "340px", xs: "280px" },
            height: { sm: "720px", xsm: "570px", xs: "520px" },
            boxShadow: "0px 8px 24px 0px rgba(27,46,94,0.3)",
            borderRadius: "16px",
            backgroundColor: "#fff",
          }}
        >
          <Box
            sx={{
              marginTop: "30px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Logo disableClick={true} sx={{ cursor: "default" }} />
          </Box>
          <Box
            sx={{
              marginTop: "30px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <img src={DomainSuccessImage} width={"85%"} />
          </Box>
          <Box
            sx={{
              textAlign: "center",
              marginTop: "15px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              sx={{
                fontSize: "20px",
                lineHeight: "28px",
                fontWeight: "500",
                mb: 1,
              }}
            >
              {t("Congratulations")}
            </Typography>
            <Typography
              sx={{
                fontSize: "16px",
                lineHeight: "24px",
                fontWeight: "400",
                maxWidth: "310px",
              }}
            >
              {t("YourDomainNow")}
            </Typography>
          </Box>
          <Box sx={{ width: "80%", margin: "auto", marginTop: "30px" }}>
            <Button
              size="large"
              variant="contained"
              sx={{
                marginLeft: 1,
                marginRight: 1,
                paddingLeft: 3,
                paddingRight: 3,
                margin: "auto",
                width: "100%",
                background: "#4680FF",
              }}
              onClick={() => {
                handleOnClick();
              }}
            >
              {t("BackFoxly")}
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default DomainSuccess;
