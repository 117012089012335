import React from "react";
import { useQuery } from "react-query";
import * as URL from "../../api/url";
import api from "../../api/api";

export const useLinkUrlChartData = ({
  apiType,
  lastPath,
  payload = null,
  extra = "",
  url_shortner_id,
}) => {
  const { data, error, refetch, isLoading, isFetching } = useQuery(
    [`link-chart-url`],
    async () => {
      if (!!url_shortner_id) return;
      let baseUrl = apiType === "POST" ? api.postWithToken : api.getWithToken;
      try {
        const response = await baseUrl(
          `${URL.USER_TODAY_GRAPH}${lastPath}?type=url${extra}`,
          payload
        );
        return (
          response.data.data.daysData ||
          response.data.data.hoursData ||
          response.data.data.thisMonthData ||
          response.data.data.lastMonthData
        );
      } catch (error) {
        throw new Error("Failed to fetch account gateways");
      }
    },
    {
      enabled: false, // Set initial query to be disabled
      cacheTime: 0,
      retry: 0,
      staleTime: 0,
    }
  );

  return { data, error, refetch, isLoading, isFetching };
};

export const useLinkClickChartData = ({
  apiType,
  lastPath,
  payload = null,
  extra = "",
  url_shortner_id,
  is_bio_link,
}) => {
  const { data, error, refetch, isLoading, isFetching } = useQuery(
    [`link-chart-click`],
    async () => {
      let apiTypeWithToken =
        apiType === "POST" ? api.postWithToken : api.getWithToken;
      let baseUrl =
        url_shortner_id !== ""
          ? `${URL.USER_URL_TODAY_GRAPH}${lastPath}${extra}`
          : is_bio_link
          ? `${URL.USER_TODAY_GRAPH}${lastPath}?url_shortner_id=${url_shortner_id}`
          : `${URL.USER_TODAY_GRAPH}${lastPath}?type=click${extra}`;
      try {
        const response = await apiTypeWithToken(`${baseUrl}`, payload);
        return (
          response.data.data.data ||
          response.data.data.daysData ||
          response.data.data.hoursData ||
          response.data.data.thisMonthData ||
          response.data.data.lastMonthData
        );
      } catch (error) {
        throw new Error("Failed to fetch account gateways");
      }
    },
    {
      enabled: false, // Set initial query to be disabled
      cacheTime: 0,
      retry: 0,
      staleTime: 0,
    }
  );

  return { data, error, refetch, isLoading, isFetching };
};
