import { Box, Button, Typography, DialogContent } from "@mui/material";
import { Trash } from "iconsax-react";
import deleteImg from "./deletemodal.png";
import { LoadingButton } from "@mui/lab";
import ModelImage from "../../assets/images/Setting/ModelImage";
import { useTranslation } from "react-i18next";

const DeleteContent = ({
  handleClose,
  handleDelete,
  content,
  selected,
  isLoading,
  isShowContent,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {isShowContent ? (
        <Box display={"flex"} justifyContent={"center"}>
          <img src={deleteImg} alt="qrcode" width={"245px"} />
        </Box>
      ) : (
        <Box display={"flex"} justifyContent={"center"}>
          <ModelImage width={"245px"} />
        </Box>
      )}
      {isShowContent ? (
        <Box sx={{ textAlign: "center" }}>
          <Typography variant="h4">{content}</Typography>
          <Box>
            {t("ThisActAssInc")}
          </Box>
          <Box>{t("CLKAnaPro")}</Box>
        </Box>
      ) : (
        <DialogContent sx={{ textAlign: "center" }}>
          <div>{content}</div>
        </DialogContent>
      )}
      <Box
        sx={{ margin: "15px auto 5px" }}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Button
          variant="contained"
          sx={{ marginX: "5px" }}
          onClick={handleClose}
          disabled={isLoading}
        >
          {t("Cancel")}
        </Button>
        <LoadingButton
          loading={isLoading}
          variant="contained"
          color="error"
          startIcon={<Trash size="32" color="#FFFFFF" />}
          onClick={() => {
            handleDelete(selected);
          }}
          isLoading={isLoading}
        >
          {isLoading ? t("Loading") : t("DeleteIt")}
        </LoadingButton>
      </Box>
    </>
  );
};

export default DeleteContent;
