import { useState } from "react";
import { useTheme } from "@mui/material/styles";
import { Box, Drawer, Grid, Stack, Typography } from "@mui/material";
import MainCard from "../../../../Component/MainCard";
import IconButton from "../../../../Component/@extended/IconButton";
import SimpleBar from "../../../../Component/third_party/SimpleBar";
import MessageCard from "../../../../Component/cards/statistics/MessageCard";
import { ThemeMode } from "../../../../config";
import { Add, Flash} from "iconsax-react";
import message1 from "../../../../assets/images/widget/message/message1.png";
import message2 from "../../../../assets/images/widget/message/message2.png";
import { useTranslation } from "react-i18next";

const Customization = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen(!open);
  };

  const iconBackColorOpen =
    theme.palette.mode === ThemeMode.DARK ? "secondary.200" : "secondary.200";
  const iconBackColor =
    theme.palette.mode === ThemeMode.DARK
      ? "background.default"
      : "secondary.100";

  return (
    <>
      <Box sx={{ flexShrink: 0, ml: 1.5 }}>
        <IconButton
          color="secondary"
          variant="light"
          onClick={handleToggle}
          aria-label="settings toggler"
          size="large"
          sx={{
            color: "secondary.main",
            bgcolor: open ? iconBackColorOpen : iconBackColor,
            p: 1,
          }}
        >
          <Flash variant="Bold" />
        </IconButton>
      </Box>
      <Drawer
        sx={{ zIndex: 2001 }}
        anchor="right"
        onClose={handleToggle}
        open={open}
        PaperProps={{ sx: { width: { xs: 350, sm: 474 } } }}
      >
        {open && (
          <MainCard
            content={false}
            sx={{ border: "none", borderRadius: 0, height: "100vh" }}
          >
            <SimpleBar
              sx={{
                "& .simplebar-content": {
                  display: "flex",
                  flexDirection: "column",
                },
              }}
            >
              <Box sx={{ p: 2.5 }}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  spacing={1.5}
                >
                  <Typography variant="h5">{t("WhatAnno")}</Typography>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={0.5}
                  >
                    <Typography variant="body1" sx={{ cursor: "pointer" }}>
                      {t("MarkAsRead")}
                    </Typography>
                    <IconButton
                      color="secondary"
                      sx={{ p: 0 }}
                      onClick={handleToggle}
                    >
                      <Add size={28} style={{ transform: "rotate(45deg)" }} />
                    </IconButton>
                  </Stack>
                </Stack>
                <Grid container spacing={1.5} sx={{ mt: 2 }}>
                  <Grid item xs={12}>
                    <Typography variant="h6">{t("Today")}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <MessageCard
                      status={{ label: "New Feature", color: "success" }}
                      time="2 min ago"
                      title="Link-in-Bio: Elevate Your Brand 🚀"
                      message="Harness the power of a branded link-in-bio to highlight your brand's finest content. 🌟"
                      src={message1}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MessageCard
                      status={{ label: "Sales", color: "warning" }}
                      time="2 hour ago"
                      title="Pixel and Retargeting Ads 📣"
                      message="You can use the Dashboard to explore how many new users download reports daily and monthlyEnhance your marketing efforts by adopting the advanced Foxlyme retargeting strategies to contribute to your branding efforts and finally boost your profits!"
                      src={message2}
                    />
                  </Grid>
                </Grid>
              </Box>
            </SimpleBar>
          </MainCard>
        )}
      </Drawer>
    </>
  );
};

export default Customization;
