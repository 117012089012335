import React from "react";

const TodayClicks = () => {
  return (
    <>
      <svg
        width="52"
        height="52"
        viewBox="0 0 52 52"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M25.9997 47.6668C37.9658 47.6668 47.6663 37.9663 47.6663 26.0002C47.6663 14.034 37.9658 4.3335 25.9997 4.3335C14.0335 4.3335 4.33301 14.034 4.33301 26.0002C4.33301 37.9663 14.0335 47.6668 25.9997 47.6668Z"
          fill="white"
        />
        <path
          d="M34.9913 27.7985L31.243 29.0552C30.203 29.4019 29.4013 30.2036 29.0547 31.2436L27.798 34.9919C26.7363 38.2202 22.1863 38.1552 21.1897 34.9269L16.9647 21.3202C16.1413 18.6119 18.633 16.1202 21.298 16.9436L34.9263 21.1685C38.1547 22.1869 38.198 26.7369 34.9913 27.7985Z"
          fill="white"
        />
      </svg>
    </>
  );
};

export default TodayClicks;
