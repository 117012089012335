import HelpCenter_Icon from "../assets/navigation/HelpCenter_Icon";
import Security_Icon from "../assets/navigation/Security_Icon";
import AboutPlans_Icon from "../assets/navigation/AboutPlans_Icon";
import MyAccount from "../assets/Dashboard/MyAccount";
import Setting from "../assets/Dashboard/Setting";
import BookmarkBTN_icon from "../assets/navigation/BookmarkBTN_icon";
import FormattedMessageCustomize from "./FormattedMessageCusttomize";

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const support = (planDetails) => {
  return {
    id: "OTHER",
    title: <FormattedMessageCustomize id="OtherGroup" />,
    type: "group",
    children: [
      {
        id: "My Profile",
        title: <FormattedMessageCustomize id="MyProfile" />,
        type: "item",
        url: "/profile",
        icon: MyAccount,
        index: 18,
      },
      {
        id: "Bookmark BTN",
        title: <FormattedMessageCustomize id="BookmarkBTN" />,
        type: "item",
        url: `/bookmark`,
        icon: BookmarkBTN_icon,
        index: 19,
      },
      {
        id: "Setting",
        title: <FormattedMessageCustomize id="Settings" />,
        type: "item",
        url: "/settings",
        icon: Setting,
        index: 20,
      },
      {
        id: "plans-details",
        title: <FormattedMessageCustomize id={planDetails} />,
        type: "item",
        url: `/plans/details`,
        icon: AboutPlans_Icon,
        index: 21,
      },
      {
        id: "security",
        title: <FormattedMessageCustomize id="Security" />,
        type: "item",
        url: "/security",
        icon: Security_Icon,
      },
      {
        id: "Help Center",
        title: <FormattedMessageCustomize id="HelpCenter" />,
        type: "item",
        url: "/helpCenter",
        icon: HelpCenter_Icon,
        breadcrumbs: false,
        index: 22,
      },
    ],
  };
};

export default support;
