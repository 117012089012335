import { Link } from "react-router-dom";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import UnderConstructionIcon from "../../assets/images/UnderConstruction.png";
import { ArrowRotateRight } from "iconsax-react";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import axios from "axios";

function UnderConstruction({ path = "/" }) {
  const { t } = useTranslation();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_HOST}/api/admin/config/support_text`
      );
      if (response.data.data.data) {
        window.location.href = "/dashboard";
      }
    } catch (error) {}
  };

  return (
    <>
      <Grid
        container
        spacing={10}
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: "100vh", pt: 2, pb: 1, overflow: "hidden" }}
      >
        <Grid item xs={12}>
          <Stack direction="row">
            <Grid item>
              <Box
                sx={{
                  width: { xs: 250, sm: 350 },
                }}
              >
                <img
                  src={UnderConstructionIcon}
                  alt="Under Construction"
                  style={{ width: "100%", height: "100%" }}
                />
              </Box>
            </Grid>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={2} justifyContent="center" alignItems="center">
            <Typography variant="h1">{t("UnderConstruction")}</Typography>
            <Typography
              color="textSecondary"
              align="center"
              sx={{ width: { xs: "73%", sm: "61%" } }}
            >
              {t("HeyPleCheckMaintenanceRightNow")}
            </Typography>
            <Button
              component={Link}
              startIcon={<ArrowRotateRight />}
              to={path}
              variant="contained"
            >
              {t("RefreshPage")}
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}

export default UnderConstruction;
