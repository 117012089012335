import React from "react";

const Custom_QR_Code = () => {
  return (
    <>
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M1.83203 8.93815C1.4562 8.93815 1.14453 8.62648 1.14453 8.25065V5.95898C1.14453 3.30065 3.30786 1.14648 5.95703 1.14648H8.2487C8.62453 1.14648 8.9362 1.45815 8.9362 1.83398C8.9362 2.20982 8.62453 2.52148 8.2487 2.52148H5.95703C4.05953 2.52148 2.51953 4.06148 2.51953 5.95898V8.25065C2.51953 8.62648 2.20786 8.93815 1.83203 8.93815Z"
          fill="#5B6B79"
        />
        <path
          d="M20.1667 8.93815C19.7908 8.93815 19.4792 8.62648 19.4792 8.25065V5.95898C19.4792 4.06148 17.9392 2.52148 16.0417 2.52148H13.75C13.3742 2.52148 13.0625 2.20982 13.0625 1.83398C13.0625 1.45815 13.3742 1.14648 13.75 1.14648H16.0417C18.6908 1.14648 20.8542 3.30065 20.8542 5.95898V8.25065C20.8542 8.62648 20.5425 8.93815 20.1667 8.93815Z"
          fill="#5B6B79"
        />
        <path
          opacity="0.4"
          d="M16.043 20.8535H14.668C14.2921 20.8535 13.9805 20.5418 13.9805 20.166C13.9805 19.7902 14.2921 19.4785 14.668 19.4785H16.043C17.9405 19.4785 19.4805 17.9385 19.4805 16.041V14.666C19.4805 14.2902 19.7921 13.9785 20.168 13.9785C20.5438 13.9785 20.8555 14.2902 20.8555 14.666V16.041C20.8555 18.6993 18.6921 20.8535 16.043 20.8535Z"
          fill="#5B6B79"
        />
        <path
          d="M8.2487 20.8542H5.95703C3.30786 20.8542 1.14453 18.7 1.14453 16.0417V13.75C1.14453 13.3742 1.4562 13.0625 1.83203 13.0625C2.20786 13.0625 2.51953 13.3742 2.51953 13.75V16.0417C2.51953 17.9392 4.05953 19.4792 5.95703 19.4792H8.2487C8.62453 19.4792 8.9362 19.7908 8.9362 20.1667C8.9362 20.5425 8.62453 20.8542 8.2487 20.8542Z"
          fill="#5B6B79"
        />
        <path
          d="M8.25 4.8125H6.41667C5.37167 4.8125 4.8125 5.3625 4.8125 6.41667V8.25C4.8125 9.30417 5.37167 9.85417 6.41667 9.85417H8.25C9.295 9.85417 9.85417 9.30417 9.85417 8.25V6.41667C9.85417 5.3625 9.295 4.8125 8.25 4.8125Z"
          fill="#5B6B79"
        />
        <path
          opacity="0.4"
          d="M15.582 4.8125H13.7487C12.7037 4.8125 12.1445 5.3625 12.1445 6.41667V8.25C12.1445 9.30417 12.7037 9.85417 13.7487 9.85417H15.582C16.627 9.85417 17.1862 9.30417 17.1862 8.25V6.41667C17.1862 5.3625 16.627 4.8125 15.582 4.8125Z"
          fill="#5B6B79"
        />
        <path
          opacity="0.4"
          d="M8.25 12.1465H6.41667C5.37167 12.1465 4.8125 12.6965 4.8125 13.7507V15.584C4.8125 16.6382 5.37167 17.1882 6.41667 17.1882H8.25C9.295 17.1882 9.85417 16.6382 9.85417 15.584V13.7507C9.85417 12.6965 9.295 12.1465 8.25 12.1465Z"
          fill="#5B6B79"
        />
        <path
          d="M15.582 12.1465H13.7487C12.7037 12.1465 12.1445 12.6965 12.1445 13.7507V15.584C12.1445 16.6382 12.7037 17.1882 13.7487 17.1882H15.582C16.627 17.1882 17.1862 16.6382 17.1862 15.584V13.7507C17.1862 12.6965 16.627 12.1465 15.582 12.1465Z"
          fill="#5B6B79"
        />
      </svg>
    </>
  );
};

export default Custom_QR_Code;
