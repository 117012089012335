import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { QueryClient, QueryClientProvider } from "react-query";
import { STORAGEKEY } from "./Component/Constant";
import Locales from "./Component/Locales";
import ThemeCustomization from "./themes";
import { store } from "./store";
import ThemeRoutes from "./Routers";
import ScrollTop from "./Component/ScrollTop";
import CustomSnackbar from "./Component/CustomSnackbar";
import { ConfigProvider } from "./Contexts/ConfigContext";

function App() {
  const queryClient = new QueryClient();

  return (
    <BrowserRouter basename={process.env.REACT_APP_BASE_NAME}>
      <ConfigProvider>
        <ThemeCustomization>
          <QueryClientProvider client={queryClient}>
            <Provider store={store}>
              <Locales>
                <CustomSnackbar />
                <ScrollTop>
                  <ThemeRoutes />
                </ScrollTop>
              </Locales>
            </Provider>
          </QueryClientProvider>
        </ThemeCustomization>
      </ConfigProvider>
    </BrowserRouter>
  );
}

export default App;
