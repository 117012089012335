import React from "react";
import { useTranslation } from "react-i18next";
import { FormattedMessage } from "react-intl";

const FormattedMessageCustomize = ({ id }) => {
  const { t } = useTranslation();

  return (
    <>
      <FormattedMessage id={t(id)} />
    </>
  );
};

export default FormattedMessageCustomize;
