import React from "react";
import { alpha, useTheme } from "@mui/material/styles";

const Cup = () => {
  const theme = useTheme();

  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M16.5 18.7917H15.5833V18.5625C15.5833 17.5542 14.7583 16.7292 13.75 16.7292H11.6875V14.63C11.4583 14.6575 11.2292 14.6667 11 14.6667C10.7708 14.6667 10.5417 14.6575 10.3125 14.63V16.7292H8.25C7.24167 16.7292 6.41667 17.5542 6.41667 18.5625V18.7917H5.5C5.12417 18.7917 4.8125 19.1033 4.8125 19.4792C4.8125 19.855 5.12417 20.1667 5.5 20.1667H16.5C16.8758 20.1667 17.1875 19.855 17.1875 19.4792C17.1875 19.1033 16.8758 18.7917 16.5 18.7917Z"
        fill="white"
      />
      <path
        opacity="0.4"
        d="M5.05984 10.67C4.45484 10.4409 3.92318 10.065 3.50151 9.64337C2.64901 8.69921 2.08984 7.57171 2.08984 6.25171C2.08984 4.93171 3.12568 3.89587 4.44568 3.89587H4.95901C4.72068 4.38171 4.58318 4.92254 4.58318 5.50004V8.25004C4.58318 9.11171 4.74818 9.92754 5.05984 10.67Z"
        fill="white"
      />
      <path
        opacity="0.4"
        d="M19.9104 6.25171C19.9104 7.57171 19.3513 8.69921 18.4988 9.64337C18.0771 10.065 17.5454 10.4409 16.9404 10.67C17.2521 9.92754 17.4171 9.11171 17.4171 8.25004V5.50004C17.4171 4.92254 17.2796 4.38171 17.0413 3.89587H17.5546C18.8746 3.89587 19.9104 4.93171 19.9104 6.25171Z"
        fill="white"
      />
      <path
        d="M13.7497 1.83337H8.24967C6.22384 1.83337 4.58301 3.47421 4.58301 5.50004V8.25004C4.58301 11.7975 7.45217 14.6667 10.9997 14.6667C14.5472 14.6667 17.4163 11.7975 17.4163 8.25004V5.50004C17.4163 3.47421 15.7755 1.83337 13.7497 1.83337ZM13.603 7.74587L13.0347 8.44254C12.943 8.54337 12.8788 8.74504 12.888 8.88254L12.943 9.78087C12.9797 10.3309 12.5855 10.615 12.0722 10.4134L11.238 10.0834C11.1097 10.0375 10.8897 10.0375 10.7613 10.0834L9.92717 10.4134C9.41384 10.615 9.01967 10.3309 9.05634 9.78087L9.11134 8.88254C9.12051 8.74504 9.05634 8.54337 8.96467 8.44254L8.39634 7.74587C8.03884 7.32421 8.19467 6.85671 8.72634 6.71921L9.59717 6.49921C9.73467 6.46254 9.89967 6.33421 9.97301 6.21504L10.4588 5.46337C10.7613 4.99587 11.238 4.99587 11.5405 5.46337L12.0263 6.21504C12.0997 6.33421 12.2647 6.46254 12.4022 6.49921L13.273 6.71921C13.8047 6.85671 13.9605 7.32421 13.603 7.74587Z"
        fill="white"
      />
      <path
        opacity="0.4"
        d="M13.6038 7.7458L13.0354 8.44246C12.9438 8.5433 12.8796 8.74496 12.8888 8.88246L12.9438 9.7808C12.9804 10.3308 12.5863 10.615 12.0729 10.4133L11.2388 10.0833C11.1104 10.0375 10.8904 10.0375 10.7621 10.0833L9.92792 10.4133C9.41459 10.615 9.02042 10.3308 9.05709 9.7808L9.11209 8.88246C9.12125 8.74496 9.05709 8.5433 8.96542 8.44246L8.39709 7.7458C8.03959 7.32413 8.19542 6.85663 8.72709 6.71913L9.59792 6.49913C9.73542 6.46246 9.90042 6.33413 9.97375 6.21496L10.4596 5.4633C10.7621 4.9958 11.2388 4.9958 11.5413 5.4633L12.0271 6.21496C12.1004 6.33413 12.2654 6.46246 12.4029 6.49913L13.2738 6.71913C13.8054 6.85663 13.9613 7.32413 13.6038 7.7458Z"
        fill="white"
      />
    </svg>
  );
};

export default Cup;
