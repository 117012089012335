import { Link, useNavigate } from "react-router-dom";

// material-ui
import { Box, Button, Grid, Stack, Typography } from "@mui/material";

// project-imports

// assets
import error404 from "../../assets/images/404-error.png";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/styles";
import { STORAGEKEY } from "../../Component/Constant";

// ==============================|| ERROR 404 ||============================== //

function Error404({ path = "/" }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();

  useEffect(() => {
    const intervalId = setInterval(() => {
      localStorage.clear();
      localStorage.setItem(STORAGEKEY.THEME_MODE, theme.palette.mode);
      navigate("/auth/login");
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <Grid
        container
        spacing={10}
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: "100vh", pt: 2, pb: 1, overflow: "hidden" }}
      >
        <Grid item xs={12}>
          <Stack direction="row">
            <Grid item>
              <Box
                sx={{
                  width: { xs: 250, sm: 350 },
                }}
              >
                <img
                  src={error404}
                  alt="error 404"
                  style={{ width: "100%", height: "100%" }}
                />
              </Box>
            </Grid>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={2} justifyContent="center" alignItems="center">
            <Typography variant="h1">{t("PageNotFound")}</Typography>
            <Typography
              color="textSecondary"
              align="center"
              sx={{ width: { xs: "73%", sm: "61%" } }}
            >
             {t("OopsLookNavBackHomePage")}
            </Typography>
            <Button component={Link} to={path} variant="contained">
              {t("BackToHome")}
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}

export default Error404;
