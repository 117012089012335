import * as URL from "../../api/adminUrl";
import api from "../../api/adminApi";
import { useQuery } from "react-query";

export const useSupportive = () => {
  const { data, error, refetch } = useQuery(
    ["get-supportive"],
    async () => {
      try {
        const response = await api.getWithToken(URL.SUPPORT_TEXT_CONFIG);
        return response.data.data.data;
      } catch (error) {
        // throw new Error("Failed to fetch account gateways");
      }
    },
    {
      enabled: false, // Set initial query to be disabled
      cacheTime: 0,
      retry: 0,
      staleTime: 0,
    }
  );

  return { data, error, refetch };
};
