import PropTypes from "prop-types";
import { lazy, Suspense, useEffect, useLayoutEffect, useState } from "react";

import { Outlet, useLocation, useNavigate } from "react-router-dom";

// material-ui
import { Container, Toolbar, Box, useMediaQuery } from "@mui/material";

// project-imports
import ComponentLayout from "./ComponentLayout";

// material-ui
import { styled } from "@mui/material/styles";
import LinearProgress from "@mui/material/LinearProgress";
import { dispatch, useSelector } from "../../store";
import MainLayout from "../MainLayout";
import { DRAWER_WIDTH, MenuOrientation } from "../../config";
import useConfig from "../../Hooks/useConfig";
import { useTheme } from "@mui/system";
import Breadcrumbs from "../../Component/@extended/Breadcrumbs";
import MenuItems from "../../menu-items";
import { STORAGEKEY } from "../../Component/Constant";
import { useSupportive } from "../../Hooks/Auth/useSupportive";
import CustomAlerts from "../../Component/CustomAlerts";
import { getSupportSystemText } from "../../Helpers/dashboard";
import { useCurrentPackage } from "../../Hooks/Auth/useCurrentPackage";

const Header = lazy(() => import("../MainLayout/Header"));
const MainDrawer = lazy(() => import("../MainLayout/Drawer"));
const FooterBlock = lazy(() => import("./FooterBlock"));

// ==============================|| LOADER - WRAPPER ||============================== //

const LoaderWrapper = styled("div")(({ theme }) => ({
  position: "fixed",
  top: 0,
  left: 0,
  zIndex: 2001,
  width: "100%",
  "& > * + *": {
    marginTop: theme.spacing(2),
  },
}));

const Loader = () => (
  <LoaderWrapper>
    <LinearProgress color="primary" />
  </LoaderWrapper>
);

// ==============================|| MINIMAL LAYOUT ||============================== //

const CommonLayout = ({ layout = "blank" }) => {
  const theme = useTheme();
  const location = useLocation();
  const { data, refetch } = useSupportive();
  const { container, miniDrawer, menuOrientation } = useConfig();
  const downLG = useMediaQuery(theme.breakpoints.down("lg"));
  const { breadcrumbs } = useSelector((state) => state);

  const [showChat, setShowChat] = useState(false);
  const {
    data: ActivePackages,
    isFetching,
    // refetch: packageRefetch,
  } = useCurrentPackage();

  useEffect(() => {
    if (location?.pathname !== "/construction") {
      refetch();
    }
    // packageRefetch();
  }, []);
  const { items } = MenuItems(breadcrumbs);

  const isHorizontal =
    menuOrientation === MenuOrientation.HORIZONTAL && !downLG;

  const isLoggedIn = localStorage.getItem(STORAGEKEY.ACCESSTOKEN);
  //   const location = useLocation();
  //   let navigate = useNavigate();

  // useEffect(() => {
  //   if (!isLoggedIn) {
  //     navigate("/auth/login");
  //   } else {
  //     navigate("/dashboard");
  //   }
  // }, [isLoggedIn]);

  //new chat boat add 19/1/2024

  useEffect(() => {
    if (showChat) {
      (function (d, h, w) {
        var gist = (w.gist = w.gist || []);
        gist.methods = ["trackPageView", "identify", "track", "setAppId"];
        gist.factory = function (t) {
          return function () {
            var e = Array.prototype.slice.call(arguments);
            e.unshift(t);
            gist.push(e);
            return gist;
          };
        };
        for (var i = 0; i < gist.methods.length; i++) {
          var c = gist.methods[i];
          gist[c] = gist.factory(c);
        }
        var s = d.createElement("script");
        s.src =
          "https://chatbox.simplebase.co/projects/6614b6ce72c5b85999a0b096/widget.js";
        s.async = true;
        var e = d.getElementsByTagName(h)[0];
        e.appendChild(s);
        s.addEventListener("load", function (e) {}, false);
        if (gist.setAppId) {
          gist.setAppId("23t2mjns");
        }
        if (gist.trackPageView) {
          gist.trackPageView();
        }
      })(document, "head", window);
    }

    var gistScript = document.querySelector(
      'script[src="https://chatbox.simplebase.co/projects/6614b6ce72c5b85999a0b096/widget.js"]'
    );

    if (!showChat && gistScript) {
      gistScript?.remove();
    }

    return () => {
      if (gistScript) {
        gistScript?.parentNode?.removeChild(gistScript);
      }
    };
  }, [showChat]);

  //new chat boat end

  useEffect(() => {
    if (!isFetching && isLoggedIn) {
      if (ActivePackages?.user_active_plan === 3) {
        setShowChat(true);
      }
    } else if (ActivePackages?.user_active_plan === 1) {
      setShowChat(false);
    }
  }, [ActivePackages, isLoggedIn]);

  return (
    <>
      {(layout === "landing" || layout === "simple") && (
        <Suspense fallback={<Loader />}>
          <Box sx={{ display: "flex", width: "100%" }}>
            {!!data?.after_login_status == 1 && (
              <Box
                sx={{
                  position: "fixed",
                  top: "10px",
                  zIndex: "99999",
                  right: "20%",
                  width: "50%",
                }}
              >
                <CustomAlerts message={getSupportSystemText(data)} />
              </Box>
            )}
            <Header />
            <MainDrawer />
            <Box
              component="main"
              sx={{
                width: `calc(100% - ${DRAWER_WIDTH}px)`,
                flexGrow: 1,
                p: { xs: 0, md: 1 },
              }}
            >
              <Box
                component="main"
                sx={{
                  width: `calc(100% - ${DRAWER_WIDTH}px)`,
                  flexGrow: 1,
                  px: 4,
                }}
              >
                <Toolbar
                  sx={{
                    mt: isHorizontal ? 8 : "inherit",
                    mb: isHorizontal ? 2 : "inherit",
                  }}
                />
                {items && (
                  <Breadcrumbs
                    navigation={items}
                    title
                    titleBottom
                    card={false}
                    divider={false}
                  />
                )}
              </Box>
              <Container
                maxWidth={container ? "xl" : false}
                sx={{
                  xs: 0,
                  ...(container && { px: { xs: 3, md: 3 } }),
                  position: "relative",
                  minHeight: "calc(100vh - 280px)",
                  display: "flex",
                  flexDirection: "column",
                  maxWidth: 1920,
                  paddingLeft: "32px",
                  paddingRight: "32px",
                }}
              >
                <Outlet />
              </Container>
              <FooterBlock />
            </Box>
          </Box>
        </Suspense>
      )}
      {layout === "component" && (
        <Suspense fallback={<Loader />}>
          <Box sx={{ display: "flex", width: "100%" }}>
            {!!data?.after_login_status == 1 && (
              <Box
                sx={{
                  position: "fixed",
                  top: "10px",
                  zIndex: "99999",
                  width: "90%",
                }}
              >
                <CustomAlerts
                  message={getSupportSystemText(data)}
                  alertStyle={{ width: "50%" }}
                />
              </Box>
            )}
            <Header />
            <MainDrawer />
            <Box
              component="main"
              sx={{
                width: `calc(100% - ${DRAWER_WIDTH}px)`,
                flexGrow: 1,
                p: { xs: 2, md: 3 },
              }}
            >
              <Container
                maxWidth={container ? "xl" : false}
                sx={{
                  xs: 0,
                  ...(container && { px: { xs: 3, md: 3 } }),
                  position: "relative",
                  minHeight: "calc(100vh - 280px)",
                  display: "flex",
                  flexDirection: "column",
                  maxWidth: 1920,
                  paddingLeft: "32px",
                  paddingRight: "32px",
                }}
              >
                <Outlet />
              </Container>
            </Box>
            <FooterBlock />
          </Box>
        </Suspense>
      )}
      {layout === "blank" && <Outlet />}
      {/* {!!showChat && <TawkToChat shouldLoad={!!showChat} />} */}
    </>
  );
};

CommonLayout.propTypes = {
  layout: PropTypes.string,
};

export default CommonLayout;
