import * as URL from "../../api/url";
import api from "../../api/api";
import { useQuery } from "react-query";

export const useGetAllBulkLinks = (pagination) => {
  const { data, error, refetch, isLoading, isFetching } = useQuery(
    ["get-bulk-links"],
    async () => {
      try {
        const response = await api.getWithToken(URL.BUNCH_URLS + pagination);
        return response.data.data.data;
      } catch (error) {
        throw new Error("Failed to fetch account gateways");
      }
    },
    {
      enabled: false, // Set initial query to be disabled
      cacheTime: 0,
      retry: 0,
      staleTime: 0,
    }
  );

  return { data, error, refetch, isLoading, isFetching };
};
export const useGetAllBulkCount = (pagination) => {
  const { data, error, refetch, isFetching, isLoading } = useQuery(
    ["get-bulk-count"],
    async () => {
      try {
        const response = await api.getWithToken(
          URL.BUNCHS_URLS_COUNT + pagination
        );
        return response.data.data.count;
      } catch (error) {
        throw new Error("Failed to fetch account gateways");
      }
    },
    {
      enabled: false, // Set initial query to be disabled
      cacheTime: 0,
      retry: 0,
      staleTime: 0,
    }
  );

  return { data, error, refetch, isFetching, isLoading };
};
export const useDownloadDemoCSVFile = () => {
  const { data, error, refetch } = useQuery(
    ["download-csv"],
    async () => {
      try {
        const response = await api.get(URL.DOWNLOAD_DEMO_CSV);
        return response?.data?.data?.data;
      } catch (error) {
        throw new Error("Failed to download");
      }
    },
    {
      enabled: false, // Set initial query to be disabled
      cacheTime: 0,
      retry: 0,
      staleTime: 0,
    }
  );

  return { data, error, refetch };
};
export const useDownloadAliasDemoCSVFile = () => {
  const { data, error, refetch } = useQuery(
    ["download-alias-csv"],
    async () => {
      try {
        const response = await api.get(URL.DOWNLOAD_ALIAS_DEMO_CSV);
        return response?.data?.data?.data;
      } catch (error) {
        throw new Error("Failed to download");
      }
    },
    {
      enabled: false, // Set initial query to be disabled
      cacheTime: 0,
      retry: 0,
      staleTime: 0,
    }
  );

  return { data, error, refetch };
};
export const useDownloadAliasDemoTagsFile = () => {
  const { data, error, refetch } = useQuery(
    ["download-tags-csv"],
    async () => {
      try {
        const response = await api.get(URL.DOWNLOAD_TAGS_DEMO_CSV);
        return response?.data?.data?.data;
      } catch (error) {
        throw new Error("Failed to download");
      }
    },
    {
      enabled: false, // Set initial query to be disabled
      cacheTime: 0,
      retry: 0,
      staleTime: 0,
    }
  );

  return { data, error, refetch };
};
export const useDeleteBulk = (bunch_id) => {
  const { data, error, refetch, isLoading, isFetching } = useQuery(
    ["delete-bulk"],
    async () => {
      if (!bunch_id) return;
      try {
        const response = await api.deleteWithToken(
          URL.BUNCH_URLS + `?bunch_id=${bunch_id}`
        );
        return bunch_id;
      } catch (error) {
        throw new Error("Failed to fetch account gateways");
      }
    }
  );
  return { data, error, refetch, isLoading, isFetching };
};

export const useEditBulkStatus = (bunch_id, payload) => {
  const { data, error, refetch, isLoading, isFetching } = useQuery(
    ["edit_bulk_status"],
    async () => {
      if (!bunch_id && !payload) return;
      try {
        const response = await api.putWithToken(
          URL.BUNCHES_STATUS_EDIT + `?bunch_id=${bunch_id}`,
          payload
        );

        return response.data;
      } catch (error) {
        throw new Error("Failed to fetch account gateways");
      }
    }
  );
  return { data, error, refetch, isLoading, isFetching };
};

export const GetBunchesAllURLsCount = (bunch_id, pagination) => {
  const { data, error, refetch, isFetching } = useQuery(
    ["get-id-bulk-data-count"],
    async () => {
      try {
        const response = await api.getWithToken(
          URL.BUNCHS_ALL_URLS_COUNT + `?bunch_id=${bunch_id}` + pagination
        );
        return response.data?.data?.count;
      } catch (error) {
        throw new Error("Failed to fetch count");
      }
    },
    {
      enabled: false, // Set initial query to be disabled
      cacheTime: 0,
      retry: 0,
      staleTime: 0,
    }
  );

  return { data, error, refetch, isFetching };
};
export const GetBunchesAllURLs = (bunch_id, pagination) => {
  const { data, error, refetch, isFetching } = useQuery(
    ["get-id-bulk-all-data-"],
    async () => {
      try {
        const response = await api.getWithToken(
          URL.BUNCHS_ALL_URLS + `?bunch_id=${bunch_id}` + pagination
        );
        return response.data?.data?.data;
      } catch (error) {
        throw new Error("Failed to fetch data");
      }
    },
    {
      enabled: false, // Set initial query to be disabled
      cacheTime: 0,
      retry: 0,
      staleTime: 0,
    }
  );

  return { data, error, refetch, isFetching };
};
export const GetBunchesAllURLsWithOutPage = (bunch_id) => {
  const { data, error, refetch, isFetching } = useQuery(
    ["get-id-bulk-all-data-without-pagination"],
    async () => {
      try {
        const response = await api.getWithToken(
          URL.BUNCHS_ALL_URLS + `?bunch_id=${bunch_id}`
        );
        return response.data?.data?.data;
      } catch (error) {
        throw new Error("Failed to fetch data");
      }
    },
    {
      enabled: false, // Set initial query to be disabled
      cacheTime: 0,
      retry: 0,
      staleTime: 0,
    }
  );

  return { data, error, refetch, isFetching };
};
export const useDeleteBulkDetail = ({ short_code, url_shortner_id }) => {
  const { data, error, refetch, isLoading, isFetching } = useQuery(
    ["delete-bulk-detail"],
    async () => {
      if (!short_code && !url_shortner_id) return;
      try {
        const response = await api.deleteWithToken(
          URL.BUNCH_SHORTNER_URLS +
            `?short_code=${short_code}&url_shortner_id=${url_shortner_id}`
        );
        return response.data?.data;
      } catch (error) {
        // throw new Error("Failed to delete detail");
        return error;
      }
    }
  );
  return { data, error, refetch, isLoading, isFetching };
};
