import React from "react";

const DeletedLinks_Icon = () => {
  return (
    <>
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M14.8411 1.83337H7.16862C3.83195 1.83337 1.84277 3.82254 1.84277 7.15921V14.8317C1.84277 18.1684 3.83195 20.1575 7.16862 20.1575H14.8411C18.1778 20.1575 20.1669 18.1684 20.1669 14.8317V7.15921C20.1669 3.82254 18.1778 1.83337 14.8411 1.83337Z"
          fill="#5B6B79"
        />
        <path
          d="M15.455 7.75499C14.685 7.68165 13.9791 7.63582 13.2916 7.58999L13.2183 7.14999C13.1541 6.70999 13.0166 5.80249 11.6325 5.80249H10.3583C8.99247 5.80249 8.84581 6.67332 8.77248 7.14082L8.69914 7.57165C8.30498 7.59915 7.91998 7.61749 7.52581 7.65415L6.52663 7.75499C6.1783 7.79165 5.9308 8.09415 5.96747 8.44249C6.00413 8.76332 6.26997 9.01082 6.59081 9.01082C6.60914 9.01082 6.63663 9.01082 6.65497 9.01082L7.64497 8.90999C8.19497 8.86416 8.75413 8.81832 9.30413 8.79082C10.4225 8.74499 11.5408 8.76332 12.6683 8.81832C13.5025 8.85499 14.3733 8.91916 15.3266 9.01082C15.345 9.01082 15.3633 9.01082 15.3816 9.01082C15.7025 9.01082 15.9775 8.76332 16.005 8.44249C16.0508 8.09415 15.8033 7.78249 15.455 7.75499Z"
          fill="#5B6B79"
        />
        <path
          d="M14.5108 10.175C14.355 10.0008 14.1258 9.90918 13.8966 9.90918H8.10329C7.87413 9.90918 7.64496 10.01 7.48913 10.175C7.33329 10.3492 7.25079 10.5783 7.26912 10.8075L7.5533 14.4375C7.6083 15.2167 7.67246 16.1883 9.43246 16.1883H12.5675C14.3275 16.1883 14.3916 15.2167 14.4466 14.4375L14.7308 10.8075C14.7491 10.5783 14.6666 10.3492 14.5108 10.175Z"
          fill="#5B6B79"
        />
      </svg>
    </>
  );
};

export default DeletedLinks_Icon;
