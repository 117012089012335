import React from "react";

const BulkLinks_Icon = () => {
  return (
    <>
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M14.8411 1.83337H7.16862C3.83195 1.83337 1.84277 3.82254 1.84277 7.15921V14.8317C1.84277 18.1684 3.83195 20.1575 7.16862 20.1575H14.8411C18.1778 20.1575 20.1669 18.1684 20.1669 14.8317V7.15921C20.1669 3.82254 18.1778 1.83337 14.8411 1.83337Z"
          fill="#5B6B79"
        />
        <path
          d="M7.49813 15.3724C7.47979 15.3724 7.4523 15.3724 7.43397 15.3724C6.5448 15.2899 5.71064 14.8775 5.0873 14.2175C3.62064 12.6775 3.62064 10.175 5.0873 8.63495L7.09481 6.5266C7.80981 5.77494 8.7723 5.35327 9.79897 5.35327C10.8256 5.35327 11.7881 5.76577 12.5031 6.5266C13.9698 8.0666 13.9698 10.5691 12.5031 12.1091L11.504 13.1633C11.2381 13.4383 10.8073 13.4475 10.5323 13.1908C10.2573 12.925 10.2481 12.4941 10.5048 12.2191L11.504 11.1649C12.4756 10.1474 12.4756 8.48828 11.504 7.47994C10.5965 6.52661 9.00147 6.52661 8.08481 7.47994L6.07731 9.58827C5.10564 10.6058 5.10564 12.265 6.07731 13.2733C6.47147 13.695 7.00314 13.9516 7.5623 14.0066C7.93814 14.0433 8.21313 14.3824 8.17647 14.7583C8.14897 15.1066 7.84646 15.3724 7.49813 15.3724Z"
          fill="#5B6B79"
        />
        <path
          d="M12.2006 16.6466C11.174 16.6466 10.2115 16.2341 9.49648 15.4732C8.02982 13.9332 8.02982 11.4307 9.49648 9.89075L10.4956 8.83659C10.7615 8.56159 11.1923 8.55239 11.4673 8.80906C11.7423 9.07489 11.7515 9.50575 11.4948 9.78075L10.4956 10.8349C9.52398 11.8524 9.52398 13.5116 10.4956 14.5199C11.4031 15.4732 12.9981 15.4824 13.9148 14.5199L15.9223 12.4116C16.894 11.3941 16.894 9.7349 15.9223 8.72656C15.5281 8.3049 14.9965 8.04824 14.4373 7.99324C14.0615 7.95657 13.7865 7.6174 13.8232 7.24157C13.8598 6.86573 14.1898 6.58158 14.5748 6.62742C15.464 6.71908 16.2982 7.1224 16.9215 7.7824C18.3882 9.3224 18.3882 11.8249 16.9215 13.3649L14.914 15.4732C14.1898 16.2341 13.2273 16.6466 12.2006 16.6466Z"
          fill="#5B6B79"
        />
      </svg>
    </>
  );
};

export default BulkLinks_Icon;
