import React from "react";

const AllLinks_Icon = () => {
  return (
    <>
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.4584 13.5208H9.16672C8.79089 13.5208 8.47922 13.2092 8.47922 12.8333C8.47922 12.4575 8.79089 12.1458 9.16672 12.1458H11.4584C13.8601 12.1458 15.8126 10.1933 15.8126 7.79167C15.8126 5.39 13.8601 3.4375 11.4584 3.4375H6.87505C4.47339 3.4375 2.52089 5.39 2.52089 7.79167C2.52089 8.8 2.87838 9.78082 3.52005 10.56C3.75838 10.8533 3.72172 11.2842 3.42839 11.5317C3.13505 11.77 2.70422 11.7333 2.45672 11.44C1.60422 10.4133 1.13672 9.12083 1.13672 7.79167C1.13672 4.62917 3.70339 2.0625 6.86589 2.0625H11.4492C14.6117 2.0625 17.1784 4.62917 17.1784 7.79167C17.1784 10.9542 14.6209 13.5208 11.4584 13.5208Z"
          fill="#5B6B79"
        />
        <path
          opacity="0.4"
          d="M15.125 19.9375H10.5417C7.37917 19.9375 4.8125 17.3708 4.8125 14.2083C4.8125 11.0458 7.37917 8.47913 10.5417 8.47913H12.8333C13.2092 8.47913 13.5208 8.79079 13.5208 9.16663C13.5208 9.54246 13.2092 9.85413 12.8333 9.85413H10.5417C8.14 9.85413 6.1875 11.8066 6.1875 14.2083C6.1875 16.61 8.14 18.5625 10.5417 18.5625H15.125C17.5267 18.5625 19.4792 16.61 19.4792 14.2083C19.4792 13.2 19.1217 12.2191 18.48 11.44C18.2417 11.1466 18.2783 10.7158 18.5717 10.4683C18.865 10.2208 19.2958 10.2666 19.5433 10.5599C20.3958 11.5866 20.8633 12.8791 20.8633 14.2083C20.8542 17.3708 18.2875 19.9375 15.125 19.9375Z"
          fill="#5B6B79"
        />
      </svg>
    </>
  );
};

export default AllLinks_Icon;
