import { useEffect, useMemo, useState } from "react";

// material-ui
import { Box, Button, useMediaQuery } from "@mui/material";

// project-imports
import Search from "./Search";
import Message from "./Message";
import Profile from "./Profile";
import Localization from "./Localization";
import Notification from "./Notification";
import MobileSection from "./MobileSection";
import MegaMenuSection from "./MegaMenuSection";

import useConfig from "../../../../Hooks/useConfig";
import { MenuOrientation, ThemeMode } from "../../../../config";
import DrawerHeader from "../../Drawer/DrawerHeader";
import { Icon } from "@iconify/react";
import { getChromeAddOnDataApi } from "../../../../services/UserConfigService";
import Cup from "../../../../assets/images/profile/Cup";
import { useCurrentPackage } from "../../../../Hooks/Auth/useCurrentPackage";
import AnimateButton from "../../../../Component/@extended/AnimateButton";
import { Link } from "react-router-dom";
import { Book } from "iconsax-react";
import IconButton from "../../../../Component/@extended/IconButton";
import { GetHelpCenterConfig } from "../../../../services/AdminService/configService";
import BecomePartner from "../../../../assets/images/icons/BecomePartner.png";
import { useTranslation } from "react-i18next";
import UsagePlanDetails from "./UsagePlanDetails";
import LightDarkMode from "./LightDarkMode";
import { useTheme } from "@mui/styles";

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { i18n, menuOrientation } = useConfig();

  const downLG = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const { data: ActivePackages, refetch: refetchActivePackage } =
    useCurrentPackage();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const localization = useMemo(() => <Localization />, [i18n]);
  const megaMenu = useMemo(() => <MegaMenuSection />, []);
  const [addOnData, setAddOnData] = useState({});
  const [supportUrl, setSupportUrl] = useState(null);
  const [displayLink, setDisplayLink] = useState(0);

  useEffect(() => {
    refetchActivePackage();
  }, []);

  useEffect(() => {
    getChromeAddOnDataApi()
      .then((res) => {
        const data = res && res.data && res.data.data;
        setAddOnData(data);
      })
      .catch((err) => {
        setAddOnData({});
      });
    GetHelpCenterConfig().then((res) => {
      const resData = res && res.data && res.data.data && res.data.data.data;
      setSupportUrl(resData.help_center_url);
      setDisplayLink(resData);
    });
  }, []);

  const callHelpCenter = () => {
    if (!!supportUrl) {
      window.open(supportUrl, "_blank", "noreferrer");
    }
  };

  const callBecomePartner = () => {
    if (!!supportUrl) {
      window.open("https://foxly.promotekit.com/", "_blank", "noreferrer");
    }
  };

  return (
    <>
      {menuOrientation === MenuOrientation.HORIZONTAL && !downLG && (
        <DrawerHeader open={true} />
      )}
      {/* {!downLG && <Search />} */}
      <Box
        sx={{
          width: "fit-content",
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
        }}
        className="my-27-step"
      >
        {!downLG && (
          <AnimateButton>
            <Button
              sx={{ ml: 2, py: 1, textTransform: "none" }}
              // color="warning"
              // target="_blank"
              variant="shadow"
              size="medium"
              startIcon={<img src={BecomePartner} width={22} />}
              // component={Link}
              // to={"/"}
              onClick={callBecomePartner}
            >
              {t("BecomePartner")}
            </Button>
          </AnimateButton>
        )}
        {!downLG && <UsagePlanDetails />}
        {!downLG && <LightDarkMode />}
        {!downLG &&
          (addOnData?.addOnAndroidAppData?.status === 1 ? (
            ActivePackages?.add_on ? (
              <a
                href={
                  addOnData && addOnData?.addOnAndroidAppData?.download_link
                }
                target="_blank"
                rel="noreferrer"
              >
                <IconButton
                  color="secondary"
                  variant="light"
                  aria-label="settings toggler"
                  size="large"
                  sx={{
                    p: 1,
                    ml: 1,
                    height: "50px",
                    width: "50px",
                  }}
                >
                  <Icon
                    icon="devicon:android"
                    height={34}
                    width={34}
                    style={{ cursor: "pointer" }}
                  />
                </IconButton>
              </a>
            ) : (
              <IconButton
                color="secondary"
                variant="light"
                aria-label="settings toggler"
                size="large"
                sx={{
                  p: 1,
                  ml: 1,
                  height: "50px",
                  width: "50px",
                  cursor: "default",
                }}
              >
                <Icon
                  icon="devicon-plain:android"
                  height={34}
                  width={34}
                  style={{ cursor: "default" }}
                />
              </IconButton>
            )
          ) : (
            <></>
          ))}
        {!downLG &&
          (addOnData?.addonIosAppData?.status === 1 ? (
            ActivePackages?.add_on ? (
              <a
                href={addOnData && addOnData?.addonIosAppData?.download_link}
                target="_blank"
                rel="noreferrer"
              >
                <IconButton
                  color="secondary"
                  variant="light"
                  aria-label="settings toggler"
                  size="large"
                  sx={{
                    p: 1,
                    ml: 1,
                    height: "50px",
                    width: "50px",
                  }}
                >
                  <Icon
                    icon="ri:apple-fill"
                    height={34}
                    width={34}
                    style={{
                      cursor: "pointer",
                      color:
                        theme.palette.mode === ThemeMode.DARK
                          ? theme.palette.secondary[400]
                          : theme.palette.secondary.darker,
                    }}
                  />
                </IconButton>
              </a>
            ) : (
              <IconButton
                color="secondary"
                variant="light"
                aria-label="settings toggler"
                size="large"
                sx={{
                  p: 1,
                  ml: 1,
                  height: "50px",
                  width: "50px",
                  cursor: "default",
                }}
              >
                <Icon
                  icon="fa6-brands:apple"
                  height={34}
                  width={34}
                  style={{ cursor: "default" }}
                />
              </IconButton>
            )
          ) : (
            <></>
          ))}
        {!downLG &&
          (addOnData?.mozillaExtension?.status === 1 ? (
            ActivePackages?.add_on ? (
              <a
                href={addOnData && addOnData?.mozillaExtension?.download_link}
                target="_blank"
                rel="noreferrer"
              >
                <IconButton
                  color="secondary"
                  variant="light"
                  aria-label="settings toggler"
                  size="large"
                  sx={{
                    p: 1,
                    ml: 1,
                    height: "50px",
                    width: "50px",
                  }}
                >
                  <Icon
                    icon="logos:firefox"
                    height={36}
                    width={36}
                    style={{ cursor: "pointer" }}
                  />
                </IconButton>
              </a>
            ) : (
              <IconButton
                color="secondary"
                variant="light"
                aria-label="settings toggler"
                size="large"
                sx={{
                  p: 1,
                  ml: 1,
                  height: "50px",
                  width: "50px",
                  cursor: "default",
                }}
              >
                <Icon
                  icon="mdi:firefox"
                  height={36}
                  width={36}
                  style={{ cursor: "default" }}
                />
              </IconButton>
            )
          ) : (
            <></>
          ))}
        {!downLG &&
          (addOnData?.chromeExtension?.status === 1 ? (
            ActivePackages?.add_on ? (
              <a
                href={addOnData && addOnData?.chromeExtension?.download_link}
                target="_blank"
                rel="noreferrer"
              >
                <IconButton
                  color="secondary"
                  variant="light"
                  aria-label="settings toggler"
                  size="large"
                  sx={{
                    p: 1,
                    ml: 1,
                    height: "50px",
                    width: "50px",
                  }}
                >
                  <Icon
                    icon="logos:chrome"
                    height={34}
                    width={34}
                    style={{ cursor: "pointer" }}
                  />
                </IconButton>
              </a>
            ) : (
              <IconButton
                color="secondary"
                variant="light"
                aria-label="settings toggler"
                size="large"
                sx={{
                  p: 1,
                  ml: 1,
                  height: "50px",
                  width: "50px",
                  cursor: "default",
                }}
              >
                <Icon
                  icon="devicon-plain:chrome"
                  height={34}
                  width={34}
                  style={{ cursor: "default" }}
                />
              </IconButton>
            )
          ) : (
            <></>
          ))}
      </Box>
      {!downLG &&
        (displayLink.status !== 0 ? (
          <IconButton
            color="secondary"
            variant="light"
            onClick={callHelpCenter}
            aria-label="settings toggler"
            size="large"
            sx={{
              p: 1,
              ml: 1,
              height: "50px",
              width: "50px",
              cursor: "pointer",
            }}
          >
            <Icon
              icon={"emojione-v1:books"}
              height={34}
              width={34}
              style={{ cursor: "pointer" }}
            />
          </IconButton>
        ) : (
          <IconButton
            color="secondary"
            variant="light"
            aria-label="settings toggler"
            size="large"
            sx={{
              p: 1,
              ml: 1,
              height: "50px",
              width: "50px",
              cursor: "default",
            }}
          >
            <Icon
              icon={"emojione-monotone:books"}
              height={34}
              width={34}
              style={{ cursor: "default" }}
            />
          </IconButton>
        ))}
      {/* {!downLG && megaMenu} */}
      {/* {!downLG && localization}
      {downLG && <Box sx={{ width: "100%", ml: 1 }} />} */}

      {/* <Notification /> */}
      {/* <Message /> */}
      {!downLG && ActivePackages?.user_active_plan === 1 && (
        <AnimateButton>
          <Button
            sx={{ ml: 2, py: 1 }}
            color="warning"
            // target="_blank"
            variant="shadow"
            size="medium"
            startIcon={<Cup />}
            component={Link}
            to={"/plans/details"}
          >
            {t("Upgrade")}
          </Button>
        </AnimateButton>
      )}
      {!downLG && <Profile />}
      {downLG && <MobileSection />}
    </>
  );
};

export default HeaderContent;
