import React, { useEffect } from "react";
import MainDrawer from "./Drawer";
import { Box } from "@mui/system";
import Header from "./Header";
import { Outlet } from "react-router-dom";
import useConfig from "../../Hooks/useConfig";
import MenuItems from "../../menu-items";
import { Container, Toolbar, useMediaQuery, useTheme } from "@mui/material";
import { dispatch, useSelector } from "../../store";
import { DRAWER_WIDTH, MenuOrientation } from "../../config";
import { openDrawer } from "../../store/reducers/menu";
import Breadcrumbs from "../../Component/@extended/Breadcrumbs";
import { useSupportive } from "../../Hooks/Auth/useSupportive";
import CustomAlerts from "../../Component/CustomAlerts";
import { getSupportSystemText } from "../../Helpers/dashboard";

const MainLayout = () => {
  const theme = useTheme();
  const { breadcrumbs } = useSelector((state) => state);
  const { items } = MenuItems(breadcrumbs);
  const { data, refetch } = useSupportive();
  const downXL = useMediaQuery(theme.breakpoints.down("xl"));
  const downLG = useMediaQuery(theme.breakpoints.down("lg"));
  const { container, miniDrawer, menuOrientation } = useConfig();
  const isHorizontal =
    menuOrientation === MenuOrientation.HORIZONTAL && !downLG;

  useEffect(() => {
    if (!miniDrawer) {
      dispatch(openDrawer(!downXL));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downXL]);

  useEffect(() => {
    refetch();
  }, [MenuItems]);

  return (
    <>
      <Box sx={{ display: "flex", width: "100%" }}>
        {!!data?.after_login_status == 1 && (
          <Box
            sx={{
              position: "fixed",
              top: "10px",
              zIndex: "99999",
              right: "20%",
              width: "50%",
            }}
          >
            <CustomAlerts message={getSupportSystemText(data)} />
          </Box>
        )}
        <Header />
        <MainDrawer />
        <Box
          component="main"
          sx={{
            width: `calc(100% - ${DRAWER_WIDTH}px)`,
            flexGrow: 1,
            p: { xs: 0, md: 0 },
          }}
        >
          <Box
            component="main"
            sx={{
              width: `calc(100% - ${DRAWER_WIDTH}px)`,
              flexGrow: 1,
              px: 4,
            }}
          >
            <Toolbar
              sx={{
                mt: isHorizontal ? 8 : "inherit",
                mb: isHorizontal ? 2 : "inherit",
              }}
            />
            {items && (
              <Breadcrumbs
                navigation={items}
                title
                titleBottom
                card={false}
                divider={false}
              />
            )}
          </Box>
          <Container
            maxWidth={container ? "xl" : false}
            sx={{
              xs: 0,
              ...(container && { px: { xs: 3, md: 3 } }),
              position: "relative",
              minHeight: "calc(100vh - 280px)",
              display: "flex",
              flexDirection: "column",
              maxWidth: 1920,
              paddingLeft: "32px",
              paddingRight: "32px",
            }}
          >
            <Outlet />
          </Container>
        </Box>
      </Box>
    </>
  );
};

export default MainLayout;
