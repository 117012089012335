import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
} from "@mui/material";
import { Add } from "iconsax-react";
import DeleteContent from "./DeleteContent";
import PaymentContent from "./PaymentContent";
import UpgradeContent from "./UpgradeContent";
import DowngradeContent from "./DowngradeContent";

const ActionModal = ({
  type,
  title,
  subDes,
  content,
  open,
  handleClose,
  handleDelete,
  selected,
  isLoading,
  isShowContent,
}) => {
  return (
    <Dialog onClose={handleClose} open={open}>
      <Stack
        justifyContent={"space-between"}
        direction={"row"}
        alignItems={"center"}
      >
        <DialogTitle>{title}</DialogTitle>

        <IconButton color="secondary" onClick={handleClose} sx={{ mr: 1 }}>
          <Add style={{ transform: "rotate(45deg)" }} />
        </IconButton>
      </Stack>
      <Divider />
      <DialogContent sx={{ minWidth: "425px" }}>
        { type !== "downgrade" && <Box>{subDes}</Box>}
        {type === "payment" && (
          <PaymentContent
            content={content}
            handleClose={handleClose}
            handleDelete={handleDelete}
            selected={selected}
            isLoading={isLoading}
          />
        )}
        {type === "delete" && (
          <DeleteContent
            content={content}
            handleClose={handleClose}
            handleDelete={handleDelete}
            isLoading={isLoading}
            isShowContent={isShowContent}
          />
        )}
        {type === "upgrade" && (
          <UpgradeContent
            content={content}
            handleDelete={handleDelete}
            isLoading={isLoading}
          />
        )}
        {/* DowngradeContent */}
        {type === "downgrade" && (
          <DowngradeContent
            content={content}
            handleDelete={handleDelete}
            isLoading={isLoading}
            subDes={subDes}
            handleClose={handleClose}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ActionModal;
