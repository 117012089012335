import PropTypes from "prop-types";
import { Box } from "@mui/material";
import { dispatch } from "../store";
import { openSnackbar } from "../store/reducers/snackbar";

export const notificationToast = (message, alertType) => {
  dispatch(
    openSnackbar({
      open: true,
      message,
      variant: "alert",
      alert: {
        color: alertType,
      },
      close: false,
    })
  );
};
export const passwordRegExp =
  /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,16}$/;
// /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/;

export const regexQuery = /^(https:\/\/|ftp:\/\/|mailto:).+/i;
export const regexURL = /^(ftp|http|https):\/\/[^ "]+$/;
export const popular = "popular";
// "^(mailto:)?[a-z0-9%+_.-]+@[a-z0-9.-]+\\.[a-z]{2,5}(:[0-9]{1,5})?(/.*)?|(https?)://[a-z0-9-]+([-.][a-z0-9]+)*\\.[a-z]+(/[^\\s]*)?(:[0-9]{1,5})?([/?].*)?$";

export const fileDownload = (url, fileName) => {
  const downloadLink = document.createElement("a");
  downloadLink.href = url;
  downloadLink.download = fileName;
  // Trigger the click event to start the download
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

export function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  value: PropTypes.number,
  index: PropTypes.number,
};

export function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const FormatNumber = (num) => {
  if (num >= 1e9) {
    return Math.floor(num / 1e9) + "B"; // Billion
  } else if (num >= 1e6) {
    return Math.floor(num / 1e6) + "M"; // Million
  } else if (num >= 1e3) {
    return Math.floor(num / 1e3) + "K"; // Thousand
  } else {
    return num; // Less than a thousand
  }
};

export const advanceTabList = [
  "OperatingSystem",
  "Country",
  "Browser",
  "City",
  "Referrer",
];

export const steps = [
  {
    target: "#root",
    content:
      "Boost your links with Foxly! The ultimate tool for shortening URLs, creating link bios, branding, and more. Take your marketing, e-commerce, and affiliate game to the next level!",
    title: "Welcome to Foxly!",
    disableBeacon: true,
    placement: "center",
  },
  {
    target: ".my-23-step",
    title: "Extensions 🔌",
    content:
      "Meet the Foxly Extension! Easily shorten and handle links from your browser. See how they're doing without leaving your window. Keep it simple with Foxly! 🌟",
    placement: "bottom",
  },
  {
    target: ".my-24-step",
    title: "Shorten URLs 🚀",
    content:
      "Foxly URL Shortener offers easy link customization, expiration dates, QR code creation, password protection, and more! It's perfect for businesses",
    placement: "top",
  },
  {
    target: ".my-25-step",
    title: "Latest and Popular Links 🔥",
    content:
      "Here you can see your recently created links and popular links with clicks.",
    placement: "top",
  },
  {
    target: ".my-26-step",
    title: "Statistics Cards 📊",
    content:
      "Here, you can obtain basic information about links, spaces, domains, clicks, link-bio clicks, and deleted links.",
    placement: "right",
  },
  {
    target: ".my-27-step",
    title: "Advanced Statistics 📊",
    content:
      "Track your links effortlessly! Discover click counts, origins, and popular devices. Enhance your marketing strategies with this valuable data. Stay current with real-time insights, adapt quickly, and watch your campaigns succeed!",
    placement: "right",
  },
  {
    target: ".my-2-step",
    title: "Links 🔗",
    content:
      "Foxly URL Shortener offers robust features for efficient link management: custom links, expiration dates, retargeting, QR codes, password protection, integrations, and more.",
    placement: "right",
  },
  {
    target: ".my-3-step",
    title: "Deep Linking 🔗",
    content:
      "When users click a short link, if the app is installed on their device, they seamlessly land in the app; if it’s not, they are directed to the browser page.",
    placement: "right",
  },
  {
    target: ".my-4-step",
    title: "Link Cloaking 🔒",
    content:
      "Enhance your link security by hiding the original URL to protect your privacy and control access.",
    placement: "right",
  },
  {
    target: ".my-5-step",
    title: "Bulk Links 🌐",
    content:
      "Save time managing multiple URLs with our Bulk URL Generator using CSV files. Simply upload your list, and we'll handle the rest. Gain flexibility and control over your link management to efficiently optimize your marketing efforts.",
    placement: "right",
  },
  {
    target: ".my-6-step",
    title: "Link-Bio ✨",
    content:
      "Make your brand shine online with a powerful link, attracting more visitors and engagement. Connect easily across social media, personalize your page with text and media in seconds, and turn clicks into customers with targeted strategies.",
    placement: "right",
  },
  {
    target: ".my-7-step",
    title: "Spaces 📂",
    content:
      "Spaces or Groups feature allows businesses and organizations to organize their links into separate groups, making it easier to manage and share specific sets of links with different places.",
    placement: "right",
  },
  {
    target: ".my-8-step",
    title: "Domains 🌐",
    content:
      "With Foxly's Custom Domains, create branded links like https://nike.com/shoes. Boost brand trust, clicks, and manage links easily for better marketing.",
    placement: "right",
  },
  {
    target: ".my-9-step",
    title: "Custom QR Code 📱",
    content:
      "Create personalized QR codes that reflect your brand or message. Customize colors, logos, and design elements to make your QR codes unique.",
    placement: "right",
  },
  {
    target: ".my-10-step",
    title: "Share Files 🗃️",
    content:
      "Share files with others easily and quickly. Just upload your files and send the link to anyone you want.",
    placement: "right",
  },
  {
    target: ".my-11-step",
    title: "Import Links 📥",
    content:
      "Easily import links from various platforms and manage them in one place.",
    placement: "right",
  },
  {
    target: ".my-12-step",
    title: "Favourite Links 🌟",
    content:
      "Easily access your most important links by adding them to your Favourites option. This feature allows you to quickly find and use your preferred links without having to search through your entire list. Simply click on the Favourites icon to save a link to your Favourite Links",
    placement: "right",
  },
  {
    target: ".my-13-step",
    title: "All Links 🔗",
    content:
      "You can see all the short links you have created and information about them. Additionally, you can edit, delete links, and download a .csv file of all links.",
    placement: "right",
  },
  {
    target: ".my-14-step",
    title: "Daily Analytics 📈",
    content:
      "You can see all the short links you have created and information about them date-wise. Additionally, you can edit links, delete links, and download a .csv file of all links.",
    placement: "right",
  },
  {
    target: ".my-15-step",
    title: "Deleted Links 🗑",
    content: "You can see all the short links you have deleted previously.",
    placement: "right",
  },
  {
    target: ".my-16-step",
    title: "Reseller 🎟️",
    content:
      "At Foxly, we offer more than just URL shortening. Introducing Reseller Coupons, a feature to increase your revenue and business reach. Create unique discount codes for users to sell to their audience, opening new business possibilities for you.",
    placement: "right",
  },
  {
    target: ".my-17-step",
    title: "Coupon or License Key 🗝",
    content: "Apply coupon code and unlock premium features.",
    placement: "right",
  },
  {
    target: ".my-18-step",
    title: "My Profile 😎",
    content: "You can see and change your personal information and password.",
    placement: "right",
  },
  {
    target: ".my-19-step",
    title: "Bookmark 📖",
    content:
      "Shorten any site instantly! Drag this link to your bookmarks. If you're logged in, it will save to your account for later. Enjoy easy access anytime!",
    placement: "right",
  },
  {
    target: ".my-20-step",
    title: "Settings ⚙",
    content:
      "You can set auto-expiration for links, auto-deletion for links, receive Telegram notifications, retain data for clicks and scans, access the API, and delete your account.",
    placement: "right",
  },
  {
    target: ".my-21-step",
    title: "Plan Details 🧾",
    content: "You can upgrade and downgrade your plan.",
    placement: "right",
  },
  {
    target: ".my-22-step",
    title: "Help Center",
    content:
      "If you have trouble at any point, check out our help center or reach out to support via message, and we’ll do our best to help.",
    placement: "right",
  },
];
