import React from "react";

const ImportLink_Icon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <path
        fill="#5B6B79"
        d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81v8.37C2 19.83 4.17 22 7.81 22h8.37c3.64 0 5.81-2.17 5.81-5.81V7.81C22 4.17 19.83 2 16.19 2z"
        opacity=".4"
      ></path>
      <path
        fill="#5B6B79"
        d="M7.75 17.5h4.83c.41 0 .75-.34.75-.75s-.34-.75-.75-.75H9.56l7.72-7.72c.29-.29.29-.77 0-1.06a.742.742 0 00-.53-.22c-.19 0-.38.07-.53.22L8.5 14.94v-3.02c0-.41-.34-.75-.75-.75s-.75.34-.75.75v4.83c0 .41.34.75.75.75z"
      ></path>
    </svg>
  );
};

export default ImportLink_Icon;
