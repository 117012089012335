import PropTypes from "prop-types";
import { lazy, Suspense, useEffect, useState } from "react";

import { Outlet, useLocation, useNavigate } from "react-router-dom";

// material-ui
import { Container, Toolbar, Box, useMediaQuery } from "@mui/material";

// project-imports
import ComponentLayout from "./ComponentLayout";

// material-ui
import { styled } from "@mui/material/styles";
import LinearProgress from "@mui/material/LinearProgress";
import { dispatch, useSelector } from "../../store";
import MainLayout from "../MainLayout";
import { DRAWER_WIDTH, MenuOrientation } from "../../config";
import useConfig from "../../Hooks/useConfig";
import { useTheme } from "@mui/system";
import Breadcrumbs from "../../Component/@extended/Breadcrumbs";
import MenuItems from "../../menu-items";
import { STORAGEKEY } from "../../Component/Constant";
import { useSupportive } from "../../Hooks/Auth/useSupportive";
import CustomAlerts from "../../Component/CustomAlerts";
import { getSupportSystemText } from "../../Helpers/dashboard";
import withAuth from "../../HOC/Auth";
import ReactJoyride from "react-joyride";
import { steps } from "../../Helpers/common";
import { useAccount, useOnboardingUpdate } from "../../Hooks/Other/useAccount";
import ConfettiBlast from "../../Component/ConfettiBlast";

const Header = lazy(() => import("../MainLayout/Header"));
const MainDrawer = lazy(() => import("../MainLayout/Drawer"));
const FooterBlock = lazy(() => import("./FooterBlock"));

// ==============================|| LOADER - WRAPPER ||============================== //

const LoaderWrapper = styled("div")(({ theme }) => ({
  position: "fixed",
  top: 0,
  left: 0,
  zIndex: 2001,
  width: "100%",
  "& > * + *": {
    marginTop: theme.spacing(2),
  },
}));

const Loader = () => (
  <LoaderWrapper>
    <LinearProgress color="primary" />
  </LoaderWrapper>
);

// ==============================|| MINIMAL LAYOUT ||============================== //

const CommonLayout = ({ layout = "blank" }) => {
  const theme = useTheme();
  const { data, refetch } = useSupportive();
  const [renderBlast, setRenderBlast] = useState(false);
  const {
    data: meData,
    refetch: meDataRefetch,
    isFetching: meIsFetching,
    isLoading: meDataISLoading,
  } = useAccount();
  const { mutate: updateOnboarding, data: successOnBoarding } =
    useOnboardingUpdate();
  const { componentDrawerOpen } = useSelector((state) => state.menu);
  const { container, miniDrawer, menuOrientation } = useConfig();
  const downLG = useMediaQuery(theme.breakpoints.down("lg"));
  const { breadcrumbs } = useSelector((state) => state);

  const { items } = MenuItems(breadcrumbs);
  const location = useLocation();
  useEffect(() => {
    if (location?.pathname !== "/construction") {
      refetch();
    }
    meDataRefetch();
  }, []);

  useEffect(() => {
    if (!!successOnBoarding) {
      setRenderBlast(true);
    }
  }, [successOnBoarding]);

  const isHorizontal =
    menuOrientation === MenuOrientation.HORIZONTAL && !downLG;

  // const isLoggedIn = localStorage.getItem(STORAGEKEY.ACCESSTOKEN);
  //   const location = useLocation();
  //   let navigate = useNavigate();

  // useEffect(() => {
  //   if (!isLoggedIn) {
  //     navigate("/auth/login");
  //   } else {
  //     navigate("/dashboard");
  //   }
  // }, [isLoggedIn]);

  return (
    <>
      {(layout === "landing" || layout === "simple") && (
        <Suspense fallback={<Loader />}>
          {!meData?.onbording_show && !meIsFetching && !meDataISLoading && (
            <>
              <ReactJoyride
                steps={steps}
                run={true}
                continuous={true}
                showSkipButton={true}
                confettiSource={{ x: 180, y: 180, w: window.width, h: 0 }}
                callback={({ action, lifecycle, index }) => {
                  if (index >= 6) {
                    document.body.style.overflow = "hidden";
                  } else {
                    document.body.style.overflowY = "scroll";
                  }
                  if (
                    (action === "skip" && lifecycle === "complete") ||
                    (action === "reset" && lifecycle === "init")
                  ) {
                    if (!meData?.onbording_show) {
                      updateOnboarding({ payload: { onbording_show: true } });
                    }
                  }
                }}
                styles={{
                  beaconOuter: { overflow: "hidden" },
                  options: {
                    zIndex: 10000,
                    overflow: "hidden",
                  },
                  tooltip: {
                    borderRadius: "16px",
                    top: "50%",
                  },
                  tooltipContainer: { textAlign: "left" },
                  tooltipTitle: { padding: "0px 10px" },
                  tooltipFooter: { margin: "0" },
                  buttonNext: { backgroundColor: "#4680ff", outline: "none" },
                  buttonBack: { color: "#4680ff", outline: "none" },
                }}
                hideCloseButton
                scrollOffset={500}
                disableOverlayClose={true}
              />
            </>
          )}
          {renderBlast && <ConfettiBlast calls={renderBlast} />}
          <Box sx={{ display: "flex", width: "100%" }}>
            {!!data?.after_login_status == 1 && (
              <Box
                sx={{
                  position: "fixed",
                  top: "10px",
                  zIndex: "99999",
                  right: "20%",
                  width: "50%",
                }}
              >
                <CustomAlerts message={getSupportSystemText(data)} />
              </Box>
            )}
            <Header />
            <MainDrawer />
            <Box
              component="main"
              sx={{
                width: `calc(100% - ${DRAWER_WIDTH}px)`,
                flexGrow: 1,
                p: { xs: 0, md: 1 },
              }}
            >
              <Box
                component="main"
                sx={{
                  width: `calc(100% )`,
                  flexGrow: 1,
                  px: 4,
                }}
              >
                <Toolbar
                  sx={{
                    mt: isHorizontal ? 8 : "inherit",
                    mb: isHorizontal ? 2 : "inherit",
                  }}
                />
                {items && (
                  <Breadcrumbs
                    navigation={items}
                    title
                    titleBottom
                    card={false}
                    divider={false}
                  />
                )}
              </Box>
              <Container
                maxWidth={container ? "xl" : false}
                sx={{
                  xs: 0,
                  ...(container && { px: { xs: 3, md: 3 } }),
                  position: "relative",
                  minHeight: "calc(100vh - 280px)",
                  display: "flex",
                  flexDirection: "column",
                  maxWidth: 1920,
                  paddingLeft: "32px",
                  paddingRight: "32px",
                }}
              >
                <Outlet />
              </Container>
              <FooterBlock />
            </Box>
          </Box>
        </Suspense>
      )}
      {layout === "component" && (
        <Suspense fallback={<Loader />}>
          <Box sx={{ display: "flex", width: "100%" }}>
            {!!data?.after_login_status == 1 && (
              <Box
                sx={{
                  position: "fixed",
                  top: "10px",
                  zIndex: "99999",
                  width: "90%",
                }}
              >
                <CustomAlerts
                  message={getSupportSystemText(data)}
                  alertStyle={{ width: "50%" }}
                />
              </Box>
            )}
            <Header />
            <MainDrawer />
            <Box
              component="main"
              sx={{
                width: `calc(100% - ${DRAWER_WIDTH}px)`,
                flexGrow: 1,
                p: { xs: 2, md: 3 },
              }}
            >
              <Container
                maxWidth={container ? "xl" : false}
                sx={{
                  xs: 0,
                  ...(container && { px: { xs: 3, md: 3 } }),
                  position: "relative",
                  minHeight: "calc(100vh - 280px)",
                  display: "flex",
                  flexDirection: "column",
                  maxWidth: 1920,
                  paddingLeft: "32px",
                  paddingRight: "32px",
                }}
              >
                <Outlet />
              </Container>
            </Box>
            <FooterBlock />
          </Box>
        </Suspense>
      )}
      {layout === "blank" && <Outlet />}
    </>
  );
};

CommonLayout.propTypes = {
  layout: PropTypes.string,
};

export default withAuth(CommonLayout);
