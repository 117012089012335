import Windows from "../assets/Dashboard/Windows.png";
import Linux from "../assets/Dashboard/Linux.png";
import Ios from "../assets/Dashboard/Ios.png";
import Android from "../assets/Dashboard/Android.png";
import OtherOs from "../assets/Dashboard/OtherOs.png";
import MacOs from "../assets/Dashboard/MacOs.png";
import Firefox from "../assets/Dashboard/Firefox.png";
import Opera from "../assets/Dashboard/Opera.png";
import Safari from "../assets/Dashboard/Safari.png";
import Chrome from "../assets/Dashboard/Chrome.png";
import Edge from "../assets/Dashboard/Edge.png";
import OtherBrowser from "../assets/Dashboard/OtherBrowser.png";
import Extension from "../assets/Dashboard/Extension.png";
import WebPlatform from "../assets/Dashboard/WebPlatform.png";

export const CountryOsBrowserQueryData = (
  expression,
  platform,
  url_shortner_id = "",
  is_bio_link = "url_shortner_id"
) => {
  switch (expression) {
    case "today":
      return {
        apiType: "GET", // apiType ,
        lastPath: "", // lastPath /lastdays || /month || /dynamic_dates,
        url_shortner_id: url_shortner_id,
        day: "today", // day today||yesterday,
        auth: "web", // auth
        search: "", // search,
        page: 1, // page,
        limit: "", // limit,
        platform, // platform country ||os ||browser,
        type: "click", // type,
        payload: null, // payload  {days: 7 || 30}  {start_date: "2023-11-08", end_date: "2023-11-16"}}
        is_bio_link,
      };
      break;
    case "yesterday":
      return {
        apiType: "GET",
        lastPath: "",
        url_shortner_id: url_shortner_id,
        day: "yesterday",
        auth: "web",
        search: "",
        page: 1,
        limit: "",
        platform,
        type: "click",
        payload: null,
        is_bio_link,
      };
      break;
    case "last7":
      return {
        apiType: "POST", // apiType ,
        lastPath: "/lastdays", // lastPath /lastdays || /month || /dynamic_dates,
        url_shortner_id: url_shortner_id,
        day: "", // day today||yesterday,
        auth: "web", // auth
        search: "", // search,
        page: 1, // page,
        limit: "", // limit,
        platform, // platform country ||os ||browser,
        type: "click", // type,
        payload: { days: 7 }, // payload  {days: 7 || 30}  {start_date: "2023-11-08", end_date: "2023-11-16"}}
        is_bio_link,
      };
      break;
    case "last30":
      return {
        apiType: "POST",
        lastPath: "/lastdays",
        url_shortner_id: url_shortner_id,
        day: "",
        auth: "web",
        search: "",
        page: 1,
        limit: "",
        platform,
        type: "click",
        payload: { days: 30 },
        is_bio_link,
      };
      break;
    case "thisMonth":
      return {
        apiType: "GET",
        lastPath: "/month",
        url_shortner_id: url_shortner_id,
        day: "",
        auth: "web",
        search: "",
        page: 1,
        limit: "",
        platform,
        type: "click",
        payload: null,
        extra: "&month=this",
        is_bio_link,
      };
      break;
    case "lastMonth":
      return {
        apiType: "GET",
        lastPath: "/month",
        url_shortner_id: url_shortner_id,
        day: "",
        auth: "web",
        search: "",
        page: 1,
        limit: "",
        platform,
        type: "click",
        payload: null,
        extra: "&month=last",
        is_bio_link,
      };
      break;
    default:
      return {
        apiType: "POST", // apiType ,
        lastPath: "/lastdays", // lastPath /lastdays || /month || /dynamic_dates,
        url_shortner_id: url_shortner_id,
        day: "", // day today||yesterday,
        auth: "web", // auth
        search: "", // search,
        page: 1, // page,
        limit: "", // limit,
        platform, // platform country ||os ||browser,
        type: "click", // type,
        payload: { days: 7 }, // payload  {days: 7 || 30}  {start_date: "2023-11-08", end_date: "2023-11-16"}}
        is_bio_link,
      };
  }
};
export const DeepLinkOsBrowserQueryData = (
  expression,
  platform,
  url_shortner_id = ""
) => {
  switch (expression) {
    case "today":
      return {
        lastPath: `/platform_stats?deep_link_id=${url_shortner_id}`, // lastPath /lastdays || /month || /dynamic_dates,
        auth: "web", // auth
        page: 1, // page,
        limit: "", // limit,
        platform, // platform country ||os ||browser,
        extra: "&day=today",
        get: true,
        payload: null,
      };
      break;
    case "yesterday":
      return {
        lastPath: `/platform_stats?deep_link_id=${url_shortner_id}`, // lastPath /lastdays || /month || /dynamic_dates,
        auth: "web", // auth
        page: 1, // page,
        limit: "", // limit,
        platform, // platform country ||os ||browser,
        extra: "&day=yesterday",
        get: true,
        payload: null,
      };
      break;
    case "last7":
      return {
        lastPath: `/platforms_last_days?deep_link_id=${url_shortner_id}`, // lastPath /lastdays || /month || /dynamic_dates,
        auth: "web", // auth
        page: 1, // page,
        limit: "", // limit,
        platform, // platform country ||os ||browser,
        extra: "",
        get: false,
        payload: { days: 7 },
      };
      break;
    case "last30":
      return {
        lastPath: `/platforms_last_days?deep_link_id=${url_shortner_id}`, // lastPath /lastdays || /month || /dynamic_dates,
        auth: "web", // auth
        page: 1, // page,
        limit: "", // limit,
        platform, // platform country ||os ||browser,
        extra: "",
        get: false,
        payload: { days: 30 },
      };
      break;
    case "thisMonth":
      return {
        lastPath: `/platforms_month?deep_link_id=${url_shortner_id}`, // lastPath /lastdays || /month || /dynamic_dates,
        auth: "web", // auth
        page: 1, // page,
        limit: "", // limit,
        platform, // platform country ||os ||browser,
        extra: "&month=this",
        get: true,
        payload: null,
      };
      break;
    case "lastMonth":
      return {
        lastPath: `/platforms_month?deep_link_id=${url_shortner_id}`, // lastPath /lastdays || /month || /dynamic_dates,
        auth: "web", // auth
        page: 1, // page,
        limit: "", // limit,
        platform, // platform country ||os ||browser,
        extra: "&month=last",
        get: true,
        payload: null,
      };
      break;
    default:
      return {
        lastPath: `/platforms_last_days?deep_link_id=${url_shortner_id}`, // lastPath /lastdays || /month || /dynamic_dates,
        auth: "web", // auth
        page: 1, // page,
        limit: "", // limit,
        platform, // platform country ||os ||browser,
        extra: "",
        get: false,
        payload: { days: 7 },
      };
  }
};
export const LinkOfBioIconOsBrowserQueryData = (
  expression,
  platform,
  Iconurl_shortner_id = "",
  is_bio_link = "Iconurl_shortner_id"
) => {
  switch (expression) {
    case "today":
      return {
        lastPath: `/iconurlplatform`, // lastPath /lastdays || /month || /dynamic_dates,
        auth: "web", // auth
        url_shortner_id: Iconurl_shortner_id,
        page: 1, // page,
        limit: "", // limit,
        platform, // platform country ||os ||browser,
        extra: "&day=today",
        type: "click",
        search: "", // search,
        apiType: "GET",
        is_bio_link,
        payload: null,
      };
      break;
    case "yesterday":
      return {
        lastPath: `/iconurlplatform`, // lastPath /lastdays || /month || /dynamic_dates,
        url_shortner_id: Iconurl_shortner_id,
        auth: "web", // auth
        page: 1, // page,
        limit: "", // limit,
        platform, // platform country ||os ||browser,
        extra: "&day=yesterday",
        type: "click",
        search: "", // search,
        apiType: "GET",
        is_bio_link,
        payload: null,
      };
      break;
    case "last7":
      return {
        lastPath: `/linkbioiconurl/lastdays`, // lastPath /lastdays || /month || /dynamic_dates,
        url_shortner_id: Iconurl_shortner_id,
        auth: "web", // auth
        page: 1, // page,
        limit: "", // limit,
        platform, // platform country ||os ||browser,
        extra: "&selectType=link-bio-iconurl",
        type: "click",
        search: "", // search,
        apiType: "POST",
        is_bio_link,
        payload: { days: 7 },
      };
      break;
    case "last30":
      return {
        lastPath: `/linkbioiconurl/lastdays`, // lastPath /lastdays || /month || /dynamic_dates,
        url_shortner_id: Iconurl_shortner_id,
        auth: "web", // auth
        page: 1, // page,
        limit: "", // limit,
        platform, // platform country ||os ||browser,
        extra: "&selectType=link-bio-iconurl",
        type: "click",
        search: "", // search,
        apiType: "POST",
        is_bio_link,
        payload: { days: 30 },
      };
      break;
    case "thisMonth":
      return {
        lastPath: `/linkbioiconurl/month`, // lastPath /lastdays || /month || /dynamic_dates,
        url_shortner_id: Iconurl_shortner_id,
        auth: "web", // auth
        page: 1, // page,
        limit: "", // limit,
        platform, // platform country ||os ||browser,
        extra: "&month=this",
        type: "click",
        is_bio_link,
        search: "", // search,
        apiType: "GET",
        payload: null,
      };
      break;
    case "lastMonth":
      return {
        lastPath: `/linkbioiconurl/month`, // lastPath /lastdays || /month || /dynamic_dates,
        url_shortner_id: Iconurl_shortner_id,
        auth: "web", // auth
        page: 1, // page,
        limit: "", // limit,
        platform, // platform country ||os ||browser,
        extra: "&month=last",
        type: "click",
        is_bio_link,
        search: "", // search,
        apiType: "GET",
        payload: null,
      };
      break;
    default:
      return {
        lastPath: `/linkbioiconurl/lastdays`, // lastPath /lastdays || /month || /dynamic_dates,
        url_shortner_id: Iconurl_shortner_id,
        auth: "web", // auth
        page: 1, // page,
        limit: "", // limit,
        platform, // platform country ||os ||browser,
        extra: "",
        type: "click",
        is_bio_link,
        search: "", // search,
        apiType: "GET",
        payload: { days: 7 },
      };
  }
};
export const SpaceBulkLinkStateQueryData = (
  expression,
  platform,
  _id,
  uniq_id
) => {
  switch (expression) {
    case "today":
      return {
        lastPath: "?day=today",
        auth: "web",
        search: "",
        page: 1,
        limit: "",
        platform,
        type: "click",
        _id,
        extra: "",
        get: true,
        payload: null,
        uniq_id,
      };
      break;
    case "yesterday":
      return {
        lastPath: "?day=yesterday",
        auth: "web",
        search: "",
        page: 1,
        limit: "",
        platform,
        type: "click",
        _id,
        extra: "",
        get: true,
        payload: null,
        uniq_id,
      };
      break;
    case "last7":
      return {
        lastPath: "/lastdays_files?day=",
        auth: "web",
        search: "",
        page: 1,
        limit: "",
        platform,
        type: "click",
        _id,
        extra: "",
        get: false,
        payload: { days: 7 },
        uniq_id,
      };
      break;
    case "last30":
      return {
        lastPath: "/lastdays_files?day=",
        auth: "web",
        search: "",
        page: 1,
        limit: "",
        platform,
        type: "click",
        _id,
        extra: "",
        get: false,
        payload: { days: 30 },
        uniq_id,
      };
      break;
    case "thisMonth":
      return {
        lastPath: "/month_files?month=this",
        auth: "web",
        search: "",
        page: 1,
        limit: "",
        platform,
        type: "click",
        _id,
        extra: "",
        get: true,
        payload: null,
        uniq_id,
      };
      break;
    case "lastMonth":
      return {
        lastPath: "/month_files?month=last",
        auth: "web",
        search: "",
        page: 1,
        limit: "",
        platform,
        type: "click",
        _id,
        extra: "",
        get: true,
        payload: null,
        uniq_id,
      };
      break;
    default:
      return {
        lastPath: "/lastdays_files?day=",
        auth: "web",
        search: "",
        page: 1,
        limit: "",
        platform,
        type: "click",
        _id,
        extra: "",
        get: false,
        payload: { days: 7 },
        uniq_id,
      };
  }
};
export const CustomQRCodeOsBrowserQueryData = (
  expression,
  platform,
  qr_link_id = "",
  is_bio_link = "qr_link_id"
) => {
  switch (expression) {
    case "today":
      return {
        lastPath: `/platform_stats`, // lastPath /lastdays || /month || /dynamic_dates,
        auth: "web", // auth
        url_shortner_id: qr_link_id,
        page: 1, // page,
        limit: "", // limit,
        platform, // platform country ||os ||browser,
        extra: "&day=today",
        search: "", // search,
        apiType: "GET",
        is_bio_link,
        payload: null,
      };
      break;
    case "yesterday":
      return {
        lastPath: `/platform_stats`, // lastPath /lastdays || /month || /dynamic_dates,
        url_shortner_id: qr_link_id,
        auth: "web", // auth
        page: 1, // page,
        limit: "", // limit,
        platform, // platform country ||os ||browser,
        extra: "&day=yesterday",
        search: "", // search,
        apiType: "GET",
        is_bio_link,
        payload: null,
      };
      break;
    case "last7":
      return {
        lastPath: `/platforms_last_days`, // lastPath /lastdays || /month || /dynamic_dates,
        url_shortner_id: qr_link_id,
        auth: "web", // auth
        page: 1, // page,
        limit: "", // limit,
        platform, // platform country ||os ||browser,
        extra: "",
        search: "", // search,
        apiType: "POST",
        is_bio_link,
        payload: { days: 7 },
      };
      break;
    case "last30":
      return {
        lastPath: `/platforms_last_days`, // lastPath /lastdays || /month || /dynamic_dates,
        url_shortner_id: qr_link_id,
        auth: "web", // auth
        page: 1, // page,
        limit: "", // limit,
        platform, // platform country ||os ||browser,
        extra: "",
        search: "", // search,
        apiType: "POST",
        is_bio_link,
        payload: { days: 30 },
      };
      break;
    case "thisMonth":
      return {
        lastPath: `/platforms_month`, // lastPath /lastdays || /month || /dynamic_dates,
        url_shortner_id: qr_link_id,
        auth: "web", // auth
        page: 1, // page,
        limit: "", // limit,
        platform, // platform country ||os ||browser,
        extra: "&month=this",
        is_bio_link,
        search: "", // search,
        apiType: "GET",
        payload: null,
      };
      break;
    case "lastMonth":
      return {
        lastPath: `/platforms_month`, // lastPath /lastdays || /month || /dynamic_dates,
        url_shortner_id: qr_link_id,
        auth: "web", // auth
        page: 1, // page,
        limit: "", // limit,
        platform, // platform country ||os ||browser,
        extra: "&month=last",
        is_bio_link,
        search: "", // search,
        apiType: "GET",
        payload: null,
      };
      break;
    default:
      return {
        lastPath: `/platforms_last_days`, // lastPath /lastdays || /month || /dynamic_dates,
        url_shortner_id: qr_link_id,
        auth: "web", // auth
        page: 1, // page,
        limit: "", // limit,
        platform, // platform country ||os ||browser,
        extra: "",
        is_bio_link,
        search: "", // search,
        apiType: "GET",
        payload: { days: 7 },
      };
  }
};
export const FileUrlsOsBrowserQuerydata = (
  expression,
  platform,
  file_id = "",
  is_bio_link = "file_id"
) => {
  switch (expression) {
    case "today":
      return {
        lastPath: `/fileurlhoursdata`, // lastPath /lastdays || /month || /dynamic_dates,
        url_shortner_id: file_id,
        platform,
        extra: "&day=today",
        search: "", // search,
        apiType: "GET",
        is_bio_link,
        payload: null,
      };
      break;
    case "yesterday":
      return {
        lastPath: `/fileurlhoursdata`, // lastPath /lastdays || /month || /dynamic_dates,
        url_shortner_id: file_id,
        platform, // platform country ||os ||browser,
        extra: "&day=yesterday",
        search: "", // search,
        apiType: "GET",
        is_bio_link,
        payload: null,
      };
      break;
    case "last7":
      return {
        lastPath: `/fileurlDaysdata`, // lastPath /lastdays || /month || /dynamic_dates,
        url_shortner_id: file_id,
        platform, // platform country ||os ||browser,
        extra: "",
        search: "", // search,
        apiType: "POST",
        is_bio_link,
        payload: { days: 7 },
      };
      break;
    case "last30":
      return {
        lastPath: `/fileurlDaysdata`, // lastPath /lastdays || /month || /dynamic_dates,
        url_shortner_id: file_id,
        platform, // platform country ||os ||browser,
        extra: "",
        search: "", // search,
        apiType: "POST",
        is_bio_link,
        payload: { days: 30 },
      };
      break;
    case "thisMonth":
      return {
        lastPath: `/fileurlmonthdata`, // lastPath /lastdays || /month || /dynamic_dates,
        url_shortner_id: file_id,
        platform, // platform country ||os ||browser,
        extra: "&month=this",
        is_bio_link,
        search: "", // search,
        apiType: "GET",
        payload: null,
      };
      break;
    case "lastMonth":
      return {
        lastPath: `/fileurlmonthdata`, // lastPath /lastdays || /month || /dynamic_dates,
        url_shortner_id: file_id,
        platform, // platform country ||os ||browser,
        extra: "&month=last",
        is_bio_link,
        search: "", // search,
        apiType: "GET",
        payload: null,
      };
      break;
    default:
      return {
        lastPath: `/fileurlDaysdata`, // lastPath /lastdays || /month || /dynamic_dates,
        url_shortner_id: file_id,
        platform, // platform country ||os ||browser,
        extra: "",
        is_bio_link,
        search: "", // search,
        apiType: "GET",
        payload: { days: 7 },
      };
  }
};
export const getPlatformIcon = (os, width = "40px") => {
  switch (os) {
    case 0:
      return <img src={WebPlatform} alt="os" width={width} />;
      break;
    case 1:
      return <img src={Extension} alt="os" width={width} />;
      break;
    default:
      return <img src={OtherBrowser} alt="os" width={width} />;
  }
};
export const getOsIcon = (os, width = "40px") => {
  switch (os) {
    case "Windows":
      return <img src={Windows} alt="os" width={width} />;
      break;
    case "Linux":
      return <img src={Linux} alt="os" width={width} />;
      break;
    case "Ios":
      return <img src={Ios} alt="os" width={width} />;
      break;
    case "Android":
      return <img src={Android} alt="os" width={width} />;
      break;
    case "MacOs":
      return <img src={MacOs} alt="os" width={width} />;
      break;
    default:
      return <img src={OtherOs} alt="os" width={width} />;
  }
};
export const getBrowserIcon = (os, width = "40px") => {
  switch (os) {
    case "Firefox":
      return <img src={Firefox} alt="os" width={width} />;
      break;
    case "Opera":
      return <img src={Opera} alt="os" width={width} />;
      break;
    case "Safari":
      return <img src={Safari} alt="os" width={width} />;
      break;
    case "Chrome":
      return <img src={Chrome} alt="os" width={width} />;
      break;
    case "Edge":
      return <img src={Edge} alt="os" width={width} />;
      break;
    default:
      return <img src={OtherBrowser} alt="os" width={width} />;
  }
};

export const getSupportSystemText = (supportTextData) => {
  const text = supportTextData.after_login_text;
  // const regex = /(https?\:\/\/)?(www\.)?[^\s]+\.[^\s]+/g;
  // const regex = /\b(?:https?:\/\/|www\.)[^\s]+\b/g;
  const regex = /(https?:\/\/\S+|www\.\S+)/gi;
  const matches = text.match(regex);
  // const textData = text.replace(regex, '<a href="$&" target="_main">Click Here</a>');

  let modifiedText = text;
  if (matches && matches.length > 0) {
    matches.forEach((match) => {
      let link = match;
      if (!match.startsWith("http://") && !match.startsWith("https://")) {
        link = "http://" + match;
      }
      modifiedText = modifiedText.replace(
        match,
        `<a href="${link}" target="_blank">Click Here</a>`
      );
    });
  }
  return <span dangerouslySetInnerHTML={{ __html: modifiedText }} />;
};

export const getrotatingLinkHelper = (linkData) => {
  if (!!linkData && linkData?.rotating_urls && linkData?.is_rotator === true) {
    const rotatingUrls = linkData.rotating_urls;
    const randomIndex = Math.floor(Math.random() * rotatingUrls.length);
    const randomUrl = rotatingUrls[randomIndex]?.url;

    if (randomUrl?.startsWith("mailto:")) {
      return "mailto";
    } else if (randomUrl) {
      return (
        randomUrl.match(/:\/\/(.[^/]+)/) && randomUrl.match(/:\/\/(.[^/]+)/)[1]
      );
    }
  } else if (!!linkData && linkData?.long_url) {
    const longUrl = linkData.long_url;

    if (longUrl.startsWith("mailto:")) {
      return "mailto";
    } else {
      return (
        longUrl.match(/:\/\/(.[^/]+)/) && longUrl.match(/:\/\/(.[^/]+)/)[1]
      );
    }
  }
};
