import React from "react";

const MyAccount = () => {
  return (
    <>
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M10.9997 1.83325C8.59801 1.83325 6.64551 3.78575 6.64551 6.18742C6.64551 8.54325 8.48801 10.4499 10.8897 10.5324C10.963 10.5233 11.0363 10.5233 11.0913 10.5324C11.1097 10.5324 11.1188 10.5324 11.1372 10.5324C11.1463 10.5324 11.1463 10.5324 11.1555 10.5324C13.5022 10.4499 15.3447 8.54325 15.3538 6.18742C15.3538 3.78575 13.4013 1.83325 10.9997 1.83325Z"
          fill="#5B6B79"
        />
        <path
          d="M15.6569 12.9709C13.0994 11.2659 8.92854 11.2659 6.35271 12.9709C5.18854 13.7501 4.54688 14.8042 4.54688 15.9317C4.54688 17.0592 5.18854 18.1042 6.34354 18.8742C7.62688 19.7359 9.31354 20.1667 11.0002 20.1667C12.6869 20.1667 14.3735 19.7359 15.6569 18.8742C16.8119 18.0951 17.4535 17.0501 17.4535 15.9134C17.4444 14.7859 16.8119 13.7409 15.6569 12.9709Z"
          fill="#5B6B79"
        />
      </svg>
    </>
  );
};

export default MyAccount;
