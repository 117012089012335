// ==============================|| OVERRIDES - TABLE HEAD ||============================== //

import { ThemeMode } from "../../config";

export default function TableHead(theme) {
  return {
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor:
            theme.palette.mode === ThemeMode.DARK
              ? theme.palette.secondary.lighter
              : "#EDF3FF",
          borderTop: `1px solid ${theme.palette.divider}`,
          borderBottom: `2px solid ${theme.palette.divider}`,
        },
      },
    },
  };
}
