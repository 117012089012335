import {
  Box,
  Button,
  CardContent,
  ClickAwayListener,
  Grid,
  Link,
  Paper,
  Popper,
  Typography,
} from "@mui/material";

import { ArrowDown2, ArrowUp2 } from "iconsax-react";
import React, { useEffect, useRef, useState } from "react";
import Transitions from "../../../../Component/@extended/Transitions";
import MainCard from "../../../../Component/MainCard";
import { useTheme } from "@mui/material/styles";
import { useUsagePlanDetails } from "../../../../Hooks/Plan/usePlan";
import CircleLoader from "../../../../Component/CircleLoader";
import UsagePlanCard from "./UsagePlanCard";
import { useTranslation } from "react-i18next";
import { useCurrentPackage } from "../../../../Hooks/Auth/useCurrentPackage";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { FormatNumber } from "../../../../Helpers/common";

const UsagePlanDetails = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const anchorRef = useRef(null);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const { data: packageData, refetch: packageRefetch } = useCurrentPackage();

  const {
    data: usagePlanData,
    refetch: usagePlanRefetch,
    isLoading: usagePlanIsLoading,
    isFetching: usagePlanIsFetching,
  } = useUsagePlanDetails();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
    if (!open) {
      usagePlanRefetch();
    }
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const expireDate = moment(usagePlanData?.packageExpireAt);
  const currentDate = moment();
  const isExpiringSoon = expireDate.diff(currentDate, "days") <= 3;

  useEffect(() => {
    packageRefetch();
  }, []);

  return (
    <Box sx={{ flexShrink: 0, ml: 1.5 }}>
      <Button
        variant="outlined"
        size="medium"
        endIcon={open ? <ArrowDown2 /> : <ArrowUp2 />}
        ref={anchorRef}
        aria-controls={open ? "profile-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        {t("Usage")}
      </Button>
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 9],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions
            type="grow"
            position="top-right"
            in={open}
            {...TransitionProps}
          >
            <Paper
              sx={{
                boxShadow: theme.customShadows.z1,
                width: 310,
                minWidth: 240,
                maxWidth: 310,
                [theme.breakpoints.down("md")]: {
                  maxWidth: 250,
                },
                height: "100%",
                maxHeight: "355px",
                overflowY: "auto",
                // pb: 3,
                borderRadius: 1.5,
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard border={false} content={false}>
                  <CardContent sx={{ px: 2.5, pt: 3 }}>
                    <Grid container display={"block"}>
                      {usagePlanIsLoading || usagePlanIsFetching ? (
                        <>
                          <CircleLoader
                            mt="50px"
                            mb="50px"
                            sx={{ minHeight: "155px", alignItems: "center" }}
                          />
                        </>
                      ) : (
                        <>
                          <Grid item sx={{ mb: 2.3 }}>
                            <UsagePlanCard
                              title={t("ShortURLs/Month")}
                              used={usagePlanData?.userusage?.ShortenURLs}
                              limit={usagePlanData?.packageLimits?.ShortenURLs}
                              subTitle={`${FormatNumber(
                                usagePlanData?.userusage?.ShortenURLs || 0
                              )} ${t("of")} ${
                                usagePlanData?.packageLimits?.ShortenURLs || 0
                              } ${t("created")}`}
                              proPlan={
                                usagePlanData?.packageLimits?.ShortenURLs == 0
                              }
                            />
                          </Grid>
                          <Grid item sx={{ mb: 2.3 }}>
                            <UsagePlanCard
                              title={t("Clicks/Month")}
                              used={usagePlanData?.userusage?.Clicks}
                              limit={usagePlanData?.packageLimits?.Clicks}
                              subTitle={`${FormatNumber(
                                usagePlanData?.userusage?.Clicks || 0
                              )} ${t("of")} ${
                                usagePlanData?.packageLimits?.Clicks || 0
                              } ${t("tracked")}`}
                              proPlan={
                                usagePlanData?.packageLimits?.Clicks == 0
                              }
                            />
                          </Grid>
                          <Grid item sx={{ mb: 2.3 }}>
                            <UsagePlanCard
                              title={t("Spaces/Folders")}
                              used={usagePlanData?.userusage?.spaces}
                              limit={usagePlanData?.packageLimits?.spaces}
                              subTitle={`${FormatNumber(
                                usagePlanData?.userusage?.spaces || 0
                              )} ${t("of")} ${
                                usagePlanData?.packageLimits?.spaces || 0
                              } ${t("created")}`}
                              proPlan={
                                usagePlanData?.packageLimits?.spaces == 0
                              }
                            />
                          </Grid>
                          <Grid item sx={{ mb: 2.3 }}>
                            <UsagePlanCard
                              title={t("Link-Bio")}
                              used={usagePlanData?.userusage?.linkBio}
                              limit={usagePlanData?.packageLimits?.linkBio}
                              subTitle={`${FormatNumber(
                                usagePlanData?.userusage?.linkBio || 0
                              )} ${t("of")} ${
                                usagePlanData?.packageLimits?.linkBio || 0
                              } ${t("created")}`}
                              proPlan={
                                usagePlanData?.packageLimits?.linkBio == 0
                              }
                            />
                          </Grid>
                          <Grid item sx={{ mb: 2.3 }}>
                            <UsagePlanCard
                              title={t("LinkCloaked")}
                              used={usagePlanData?.userusage?.Cloakedurl}
                              limit={
                                usagePlanData?.packageLimits?.Cloakedurl || 0
                              }
                              subTitle={`${FormatNumber(
                                usagePlanData?.userusage?.Cloakedurl || 0
                              )} ${t("of")} ${
                                usagePlanData?.packageLimits?.Cloakedurl ==
                                "false"
                                  ? 0
                                  : usagePlanData?.packageLimits?.Cloakedurl
                              } ${t("created")}`}
                              proPlan={
                                usagePlanData?.packageLimits?.Cloakedurl == 0 ||
                                usagePlanData?.packageLimits?.Cloakedurl ==
                                  "false"
                              }
                            />
                          </Grid>
                          <Grid item sx={{ mb: 2.3 }}>
                            <UsagePlanCard
                              title={t("CustomDomains")}
                              used={usagePlanData?.userusage?.CustomeDomain}
                              limit={
                                usagePlanData?.packageLimits?.CustomeDomain
                              }
                              subTitle={`${FormatNumber(
                                usagePlanData?.userusage?.CustomeDomain || 0
                              )} ${t("of")} ${
                                usagePlanData?.packageLimits?.CustomeDomain || 0
                              } ${t("connected")}`}
                              proPlan={
                                usagePlanData?.packageLimits?.CustomeDomain == 0
                              }
                            />
                          </Grid>
                          <Grid item sx={{ mb: 2.3 }}>
                            <UsagePlanCard
                              title={t("CustomQRCodes")}
                              used={usagePlanData?.userusage?.CustomeQrCode}
                              limit={
                                usagePlanData?.packageLimits?.CustomeQrCode
                              }
                              subTitle={`${FormatNumber(
                                usagePlanData?.userusage?.CustomeQrCode || 0
                              )} ${t("of")} ${
                                usagePlanData?.packageLimits?.CustomeQrCode || 0
                              } ${t("created")}`}
                              proPlan={
                                usagePlanData?.packageLimits?.CustomeQrCode == 0
                              }
                            />
                          </Grid>
                          <Grid item sx={{ mb: 2.3 }}>
                            <UsagePlanCard
                              title={t("ResellerCoupons")}
                              used={usagePlanData?.userusage?.ResellerCoupons}
                              limit={
                                usagePlanData?.packageLimits?.ResellerCoupons
                              }
                              subTitle={`${FormatNumber(
                                usagePlanData?.userusage?.ResellerCoupons || 0
                              )} ${t("of")} ${
                                usagePlanData?.packageLimits?.ResellerCoupons ||
                                0
                              } ${t("created")}`}
                              proPlan={
                                usagePlanData?.packageLimits?.ResellerCoupons ==
                                0
                              }
                            />
                          </Grid>
                          <Grid item sx={{ mb: 2.3 }}>
                            <UsagePlanCard
                              title={t("SSLCertificate")}
                              used={usagePlanData?.userusage?.SSLcertificate}
                              limit={
                                usagePlanData?.packageLimits?.SSLcertificate
                              }
                              subTitle={`${FormatNumber(
                                usagePlanData?.userusage?.SSLcertificate || 0
                              )} ${t("of")} ${
                                usagePlanData?.packageLimits?.SSLcertificate ||
                                0
                              } ${t("created")}`}
                              proPlan={
                                usagePlanData?.packageLimits?.SSLcertificate ==
                                0
                              }
                            />
                          </Grid>
                          <Grid item sx={{ mb: 2.3 }}>
                            <UsagePlanCard
                              title={t("FileStroage")}
                              used={usagePlanData?.userusage?.FileStorage}
                              limit={usagePlanData?.packageLimits?.FileStorage}
                              subTitle={`${
                                usagePlanData?.userusage?.FileStorage || 0
                              } ${t("of")} ${
                                usagePlanData?.packageLimits?.FileStorage || 0
                              } ${t("Used")}`}
                              proPlan={
                                usagePlanData?.packageLimits?.FileStorage == 0
                              }
                            />
                          </Grid>
                          {packageData?.user_active_plan === 3 &&
                          !!usagePlanData &&
                          !!usagePlanData?.packageExpireAt ? (
                            <Grid item sx={{ mt: 2.3, mb: 2.3 }}>
                              <Typography
                                sx={{
                                  color: isExpiringSoon ? "red" : "inherit",
                                }}
                              >
                                {`${t("BillingCycle")} ${moment(
                                  usagePlanData?.packageExpireAt
                                ).format("MMM DD,YYYY")})`}
                              </Typography>
                            </Grid>
                          ) : (
                            <></>
                          )}
                          {packageData?.user_active_plan === 1 ? (
                            <>
                              <Grid item sx={{ mt: 2.3, mb: 2.3 }}>
                                <Link
                                  onClick={() => {
                                    navigate("/plans/details");
                                    setOpen(false);
                                  }}
                                  sx={{
                                    color: "#4680FF",
                                    fontSize: "15px",
                                    fontWeight: 600,
                                    cursor: "pointer",
                                    textDecoration: "none",
                                  }}
                                >
                                  {t("UpgradeNow")}
                                </Link>
                                <Typography
                                  sx={{
                                    fontSize: "13px",
                                    fontWeight: 500,
                                    color: "#909398",
                                  }}
                                >
                                  {t("IncLinkVolAndImprovePer")}
                                </Typography>
                              </Grid>
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                    </Grid>
                  </CardContent>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default UsagePlanDetails;
