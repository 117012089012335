import React from "react";

const Setting = () => {
  return (
    <>
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M1.83301 11.8066V10.1933C1.83301 9.23998 2.61217 8.45165 3.57467 8.45165C5.23384 8.45165 5.91217 7.27832 5.07801 5.83915C4.60134 5.01415 4.88551 3.94165 5.71967 3.46498L7.30551 2.55748C8.02967 2.12665 8.96467 2.38332 9.39551 3.10748L9.49634 3.28165C10.3213 4.72082 11.678 4.72082 12.5122 3.28165L12.613 3.10748C13.0438 2.38332 13.9788 2.12665 14.703 2.55748L16.2888 3.46498C17.123 3.94165 17.4072 5.01415 16.9305 5.83915C16.0963 7.27832 16.7747 8.45165 18.4338 8.45165C19.3872 8.45165 20.1755 9.23082 20.1755 10.1933V11.8066C20.1755 12.76 19.3963 13.5483 18.4338 13.5483C16.7747 13.5483 16.0963 14.7216 16.9305 16.1608C17.4072 16.995 17.123 18.0583 16.2888 18.535L14.703 19.4425C13.9788 19.8733 13.0438 19.6166 12.613 18.8925L12.5122 18.7183C11.6872 17.2791 10.3305 17.2791 9.49634 18.7183L9.39551 18.8925C8.96467 19.6166 8.02967 19.8733 7.30551 19.4425L5.71967 18.535C4.88551 18.0583 4.60134 16.9858 5.07801 16.1608C5.91217 14.7216 5.23384 13.5483 3.57467 13.5483C2.61217 13.5483 1.83301 12.76 1.83301 11.8066Z"
          fill="#5B6B79"
        />
        <path
          d="M10.9997 13.9791C12.645 13.9791 13.9788 12.6453 13.9788 10.9999C13.9788 9.35457 12.645 8.02075 10.9997 8.02075C9.35433 8.02075 8.02051 9.35457 8.02051 10.9999C8.02051 12.6453 9.35433 13.9791 10.9997 13.9791Z"
          fill="#5B6B79"
        />
      </svg>
    </>
  );
};

export default Setting;
