import { Box, Button, Stack, Typography } from "@mui/material";
import upgradeImg from "../../assets/links/Upgrade.png";
import CupImg from "../../assets/images/profile/Cup";

const UpgradeContent = ({ handleDelete, content, isLoading }) => {
  return (
    <>
      <Box display={"flex"} justifyContent={"center"}>
        <img src={upgradeImg} alt="qrcode" width={"245px"} />
      </Box>
      <Box sx={{ textAlign: "center" }}>
        <Typography variant="h4" marginTop={2}>
          Upgrade Your Plan
        </Typography>
        {content}
      </Box>
      <Box
        sx={{ margin: "15px auto 5px" }}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Button variant="contained" color="warning" onClick={handleDelete}>
          <CupImg />
          <Stack marginLeft={1}> Upgrade</Stack>
        </Button>
      </Box>
    </>
  );
};

export default UpgradeContent;
