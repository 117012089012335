import { Alert, Box, Collapse, IconButton, useTheme } from "@mui/material";
import { CloseCircle } from "iconsax-react";
import React, { useState } from "react";
import getColors from "../Utils/getColors";

const CustomAlerts = ({ message, severity = "info", alertStyle = {} }) => {
  const theme = useTheme();
  const [open, setOpen] = useState(true);

  return (
    <>
      <Collapse
        in={open}
        sx={{
          width: "100%",
          zIndex: "500",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="medium"
                onClick={() => {
                  setOpen(false);
                }}
                sx={{ width: "64" }}
              >
                <CloseCircle
                  size="64"
                  color={getColors(theme, severity)?.main}
                  variant="Bold"
                />
              </IconButton>
            }
            sx={{
              mb: 2,
              border: "1px solid",
              borderColor: getColors(theme, severity)?.lighter,
              ...alertStyle,
            }}
            severity={severity}
          >
            {message}
          </Alert>
        </Box>
      </Collapse>
    </>
  );
};

export default CustomAlerts;
