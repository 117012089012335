import * as URL from "../../api/url";
import api from "../../api/api";
import { useQuery } from "react-query";

export const useStatsCard = (url_shortner_id) => {
  const { data, error, refetch } = useQuery(
    ["stats-card"],
    async () => {
      try {
        const response = await api.getWithToken(
          URL.GET_SHORT_URL_DETAILS + `?url_shortner_id=${url_shortner_id}`
        );
        return response.data.data.data;
      } catch (error) {
        throw new Error("Failed to fetch account gateways");
      }
    },
    {
      enabled: false, // Set initial query to be disabled
      cacheTime: 0,
      retry: 0,
    }
  );

  return { data, error, refetch };
};
export const useTodaysClicks = ({ date, url_shortner_id }, selectType) => {
  let reqQuery =
    selectType === "link-bio"
      ? `?url_shortner_id=${url_shortner_id}&day=${date}&selectType=${selectType}`
      : `?url_shortner_id=${url_shortner_id}&day=${date.selectDay}`;

  const { data, error, refetch, isFetching, isLoading } = useQuery(
    ["today-clicks"],
    async () => {
      try {
        const response = await api.getWithToken(
          URL.USER_URL_TODAY_GRAPH + reqQuery
        );
        return response.data.data;
      } catch (error) {
        throw new Error("Failed to fetch account gateways");
      }
    },
    {
      enabled: false, // Set initial query to be disabled
      cacheTime: 0,
      retry: 0,
    }
  );

  return { data, error, refetch, isFetching, isLoading };
};

export const useGetLinkTotalCount = (id) => {
  const { data, isLoading, error, refetch, isFetching } = useQuery(
    ["get-Link-count-data"],
    async () => {
      try {
        const response = await api.getWithToken(
          URL.LINK_ANALYTICS_COUNT + `?url_shortner_id=${id}`
        );
        return response.data.data.data;
      } catch (error) {
        throw new Error("Failed to fetch account gateways");
      }
    },
    {
      enabled: false,
      cacheTime: 0,
      retry: 0,
    }
  );

  return { data, isLoading, error, refetch, isFetching };
};

export const useLastDayClicks = ({
  apiType,
  lastPath,
  payload = null,
  extra = "",
  bio_link_url_id,
  isDateRange,
}) => {
  const { data, error, refetch, isFetching, isLoading } = useQuery(
    ["last-day-clicks"],
    async () => {
      let apiTypeWithToken =
        apiType === "POST" ? api.postWithToken : api.getWithToken;
      let baseUrl = `${
        isDateRange ? URL.DYNAMIC_STATISTICS : URL.USER_URL_TODAY_GRAPH
      }${lastPath}${bio_link_url_id}${extra}`;
      try {
        const response = await apiTypeWithToken(`${baseUrl}`, payload);
        return (
          response.data.data.data ||
          response.data.data.daysData ||
          response.data.data.hoursData ||
          response.data.data.thisMonthData ||
          response.data.data.lastMonthData
        );
      } catch (error) {
        throw new Error("Failed to fetch account gateways");
      }
    },
    {
      enabled: false, // Set initial query to be disabled
      cacheTime: 0,
      retry: 0,
    }
  );

  return { data, error, refetch, isFetching, isLoading };
};
export const useMonthClicks = (url_id, month, selectType) => {
  let reqQuery = "";
  if (selectType === "link-bio") {
    reqQuery = `?url_shortner_id=${url_id.monthIs}&month=${url_id.type}&selectType=${selectType}`;
    // reqQuery = `?url_shortner_id=${url_id.url_shortner_id}&day=${url_id.date}&selectType=${selectType}`;
  } else {
    reqQuery = `?url_shortner_id=${url_id.monthIs}&month=${url_id.type}`;
    // reqQuery = `?url_shortner_id=${url_id.url_shortner_id}&day=${url_id.date}`;
  }
  const { data, error, refetch } = useQuery(
    ["today-clicks"],
    async () => {
      try {
        const response = await api.getWithToken(
          URL.USER_URL_MONTH_GRAPH + reqQuery
        );
        return response.data.data;
      } catch (error) {
        throw new Error("Failed to fetch account gateways");
      }
    },
    {
      cacheTime: 0,
      retry: 0,
    }
  );

  return { data, error, refetch };
};
// export const UserMonthChartClickData = (url_id, month, selectType) => {
//   let reqQuery = "";
//   if (selectType === "link-bio") {
//     reqQuery = `?url_shortner_id=${url_id}&month=${month}&selectType=${selectType}`;
//   } else {
//     reqQuery = `?url_shortner_id=${url_id}&month=${month}`;
//   }

//   return new Promise(async (resolve, reject) => {
//     return api
//       .getWithToken(url.USER_URL_MONTH_GRAPH + reqQuery)
//       .then((response) => {
//         if (response) {
//           resolve(response);
//         }
//       })
//       .catch((err) => {
//         reject(err.response);
//       });
//   });
// };
