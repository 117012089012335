import React from "react";

const LinkBio_Icon = () => {
  return (
    <>
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.064 8.20415C11.0273 8.20415 10.9723 8.20415 10.9265 8.20415C9.96396 8.16748 9.20312 7.38832 9.20312 6.41665C9.20312 5.42665 10.0006 4.62915 10.9906 4.62915C11.9806 4.62915 12.7781 5.43582 12.7781 6.41665C12.7873 7.38832 12.0265 8.17665 11.064 8.20415Z"
          fill="#5B6B79"
        />
        <path
          d="M8.47883 10.9633C7.25966 11.7791 7.25966 13.1083 8.47883 13.9241C9.86299 14.8499 12.1363 14.8499 13.5205 13.9241C14.7397 13.1083 14.7397 11.7791 13.5205 10.9633C12.1363 10.0466 9.87216 10.0466 8.47883 10.9633Z"
          fill="#5B6B79"
        />
        <path
          opacity="0.4"
          d="M16.5 1.83337H5.5C3.97833 1.83337 2.75 3.05254 2.75 4.55587V14.5567C2.75 16.06 3.97833 17.2792 5.5 17.2792H6.19667C6.93 17.2792 7.62666 17.5634 8.14 18.0767L9.7075 19.6259C10.4225 20.3317 11.5867 20.3317 12.3017 19.6259L13.8692 18.0767C14.3825 17.5634 15.0883 17.2792 15.8125 17.2792H16.5C18.0217 17.2792 19.25 16.06 19.25 14.5567V4.55587C19.25 3.05254 18.0217 1.83337 16.5 1.83337ZM11 4.62921C11.99 4.62921 12.7875 5.43587 12.7875 6.41671C12.7875 7.38837 12.0175 8.16754 11.0642 8.20421C11.0275 8.20421 10.9725 8.20421 10.9267 8.20421C9.96417 8.16754 9.20333 7.38837 9.20333 6.41671C9.2125 5.43587 10.01 4.62921 11 4.62921ZM13.5208 13.9242C12.1367 14.85 9.86333 14.85 8.47917 13.9242C7.26 13.1175 7.26 11.7792 8.47917 10.9634C9.8725 10.0375 12.1458 10.0375 13.5208 10.9634C14.74 11.7792 14.74 13.1084 13.5208 13.9242Z"
          fill="#5B6B79"
        />
      </svg>
    </>
  );
};

export default LinkBio_Icon;
